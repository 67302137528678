import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Configuration} from '../models/configuration.interface';
import {HttpClient} from '@angular/common/http';
import {AliasConfig} from '../models/alias-config.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  baseUrl: string = `${environment.url}/configuration`;
  constructor(private http: HttpClient) {}

  create(configuration: Configuration): Observable<Configuration> {
    return this.http.post<Configuration>(this.baseUrl, configuration);
  }

  findAll(): Observable<Configuration[]> {
    return this.http.get<Configuration[]>(this.baseUrl);
  }

  findOne(id: number): Observable<Configuration> {
    return this.http.get<Configuration>(`${this.baseUrl}/${id}`);
  }

  update(configuration: Configuration): Observable<Configuration> {
    return this.http.put<Configuration>(`${this.baseUrl}/${configuration.id}`, configuration);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  findAllAliasConfig(): Observable<AliasConfig[]> {
    return this.http.get<AliasConfig[]>(`${this.baseUrl}/alias-config`);
  }

  findOneAliasConfig(id: number): Observable<AliasConfig> {
    return this.http.get<AliasConfig>(`${this.baseUrl}/alias-config/${id}`);
  }

  createAliasConfig(configuration: Configuration): Observable<AliasConfig> {
    return this.http.post<AliasConfig>(`${this.baseUrl}/alias-config`, configuration);
  }

  updateAliasConfig(configuration: Partial<Configuration>): Observable<AliasConfig> {
    return this.http.put<AliasConfig>(`${this.baseUrl}/alias-config/${configuration.id}`, configuration);
  }
}
