import {Injectable} from '@angular/core';
import {Breadcrumb} from "../models/breadcrumb.interface";
import {Subject} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs: Breadcrumb[] = [];
  breadcrumbSubject: Subject<Breadcrumb[]> = new Subject<Breadcrumb[]>()
  constructor(public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.parseUrlToBreadcrumbs(event.urlAfterRedirects ? event.urlAfterRedirects : event.url);
      }
    });
  }

  parseUrlToBreadcrumbs(url: string): void {
    const segments = url.split('/').filter((segment) => segment !== '');
    let result: Breadcrumb[] = [];
    let currentUrl = '';

    for (let i = 0; i < segments.length; i += 2) {
      if (i + 1 < segments.length) {
        const label = segments[i];
        const id = segments[i + 1];
        currentUrl += `/${label}/${id}`;
        result.push({ label, url: currentUrl });
      }
    }

    if (result[0]?.label && result[0].label === 'configuration'){
      result = [{label: 'Configuration List', url: '/configuration'}, ...result]
    } else {
      result = [{label: 'Organization List', url: '/organizations'}, ...result]
    }

    this.breadcrumbs = result;
    this.breadcrumbSubject.next(this.breadcrumbs);
  }
}
