import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {OrganizationService} from "../../../core/services/organization.service";
import {MatDialogRef} from "@angular/material/dialog";
import {SnackbarActionEnum} from "../../../core/enums/snackbar-action.enum";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Organization} from "../../../core/models/organization.interface";
import {ValidatorRegexEnum} from "../../../core/enums/validator-regex.enum";

@Component({
  selector: 'app-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.scss']
})
export class OrganizationCreateComponent implements OnInit {
  form: FormGroup;
  createMode: boolean = true;
  organization: Organization;
  constructor(private formBuilder: FormBuilder, private snackbar: MatSnackBar, private organizationService: OrganizationService, public dialogRef: MatDialogRef<OrganizationCreateComponent>) {
    this.form = this.formBuilder.group({
      id: [0],
      orgName: ['', [Validators.required, Validators.maxLength(255)]],
      orgAddr1: ['', [Validators.required, Validators.maxLength(255)]],
      orgAddr2: ['', [Validators.maxLength(255)]],
      orgCity: ['', [Validators.required, Validators.maxLength(50)]],
      orgState: ['', [Validators.required, Validators.maxLength(10)]],
      orgPostal: ['', [Validators.required, Validators.maxLength(10)]],
      orgCtry: ['', [Validators.required, Validators.maxLength(10)]],
      contactName: ['', [Validators.required, Validators.maxLength(255)]],
      contactEmail: ['', [Validators.maxLength(255), Validators.pattern(ValidatorRegexEnum.EMAIL)]],
      contactPhone: ['', [Validators.pattern(ValidatorRegexEnum.PHONE)]],
      contactNotes: ['', [Validators.maxLength(255)]],
      contactFax: ['', [Validators.pattern(ValidatorRegexEnum.PHONE)]],
      orgTaxId: ['', [Validators.maxLength(25)]],
    });
  }

  ngOnInit() {
    if(!this.createMode){
      this.form.patchValue(this.organization)
    }
  }

  createOrganization() {
    if (this.createMode){
      this.organizationService.create(this.form.getRawValue()).subscribe(
        (response) => {
          this.dialogRef.close(response);
          this.snackbar.open('Organization Successfully Created!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      );
    } else {
      this.organizationService.update(this.form.getRawValue()).subscribe(
        (response) => {
          this.dialogRef.close(response);
          this.snackbar.open('Organization Updated Successfully!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      )
    }
  }
}
