import {NavigationExtras} from '@angular/router';
import {UserTypeEnum} from "../../enums/user-type.enum";

export interface navItem {
	routerLink: string;
	icon: string;
	text: string;
	extras?: NavigationExtras;
  showFor: string[];
}
export const navData: navItem[] = [
	{
		routerLink: '/dashboard',
		icon: 'format_list_bulleted',
		text: 'Dashboard',
    showFor: [UserTypeEnum.ADMIN, UserTypeEnum.INTERNAL, UserTypeEnum.EXTERNAL]
	},
  {
    routerLink: '/monitor-log',
    icon: 'visibility',
    text: 'Monitor Log',
    showFor: [UserTypeEnum.EXTERNAL]
  },
];
