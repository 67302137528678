import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './core/components/login/login.component';
import {UsersListComponent} from './maintenance/users/users-list/users-list.component';
import {ChangePasswordRequestComponent} from './core/components/change-password-request/change-password-request.component';
import {FirstLoginResetPasswordComponent} from './core/components/first-login-reset-password/first-login-reset-password.component';
import {RolesListComponent} from './maintenance/roles/roles-list/roles-list.component';
import {AdminGuard} from './core/guards/admin.guard';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AccountComponent} from './core/components/account/account.component';
import {OrganizationListComponent} from './organization/organization-list/organization-list.component';
import {OrganizationComponent} from './organization/organization/organization.component';
import {ConfigurationListComponent} from './configuration/configuration-list/configuration-list.component';
import {ConfigurationComponent} from './configuration/configuration/configuration.component';
import {LocationComponent} from './organization/location/location/location.component';
import {ClientComponent} from './organization/location/client/client.component';
import {EnvironmentComponent} from './organization/location/environment/environment.component';
import {ServiceComponent} from './organization/location/service/service.component';
import {InterfaceComponent} from './organization/location/service/interface/interface/interface.component';
import {EndpointComponent} from './organization/location/service/interface/endpoint/endpoint.component';
import {MonitorLogListComponent} from "./monitor-log/monitor-log-list/monitor-log-list.component";
import {InternalGuard} from "./core/guards/internal.guard";

const routes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	},
	{path: 'login', component: LoginComponent},
	{path: 'forgot-password', component: ChangePasswordRequestComponent},
	{path: 'new-login', component: FirstLoginResetPasswordComponent},
	{path: 'account', component: AccountComponent},
	{path: 'organizations', component: OrganizationListComponent, canActivate: [InternalGuard]},
	{path: 'dashboard', component: DashboardComponent},
	{path: 'monitor-log', component: MonitorLogListComponent},
	{
		path: 'organization',
    canActivate: [InternalGuard],
		children: [
			{path: ':orgId', component: OrganizationComponent},
			{
				path: ':orgId/location',
				children: [
					{path: ':locId', component: LocationComponent},
					{path: ':locId/client', children: [{path: ':clientId', component: ClientComponent}]},
					{path: ':locId/environment', children: [{path: ':environmentId', component: EnvironmentComponent}]},
					{
						path: ':locId/service',
						children: [
							{path: ':serviceId', component: ServiceComponent},
							{
								path: ':serviceId/interface',
								children: [
									{path: ':interfaceId', component: InterfaceComponent},
									{path: ':interfaceId/endpoint', children: [{path: ':endpointId', component: EndpointComponent}]}
								]
							}
						]
					}
				]
			}
		]
	},
  {path: 'configuration', canActivate: [InternalGuard], children: [
      {path: '', component: ConfigurationListComponent},
      {path: ':id', component: ConfigurationComponent}
    ]},
	{path: 'users', component: UsersListComponent, canActivate: [AdminGuard]},
	{path: 'roles', component: RolesListComponent, canActivate: [AdminGuard]},
	{path: '**', redirectTo: '/dashboard'}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
