import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'archive'
})
export class ArchivePipe implements PipeTransform {
	transform(items: any[] | undefined, archived: boolean): any[] {
		if (items) {
			return items.filter((item: any) => {
				if (archived) {
					return item.archiveTs;
				} else {
					return !item.archiveTs;
				}
			});
		}
		return [];
	}
}
