import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfigurationService} from "../../core/services/configuration.service";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {AliasConfig} from '../../core/models/alias-config.interface';
import {MatDialog} from '@angular/material/dialog';
import {CreateConfigurationComponent} from '../modals/create-configuration/create-configuration.component';
import {UpdateConfigurationComponent} from '../modals/update-configuration/update-configuration.component';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {StatusaiEnum} from '../../core/enums/statusai-enum';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration-list.component.html',
  styleUrls: ['./configuration-list.component.scss']
})
export class ConfigurationListComponent implements OnInit {
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  sort: MatSort;
  dataSource: MatTableDataSource<AliasConfig> = new MatTableDataSource<AliasConfig>();
  columnsToDisplay: string[] = ['configDescription', 'aliasName', 'client', 'service', 'interface', 'environment', 'statusAi', 'actions'];
  loading: boolean = true;
  aliasConfigs: AliasConfig[];

  constructor(
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ){}

  ngOnInit(){
    this.configurationService.findAllAliasConfig().subscribe((response: AliasConfig[]) => {
      this.aliasConfigs = response;
      this.dataSource = new MatTableDataSource<AliasConfig>(this.aliasConfigs)
      this.loading = false;
    })
  }

  applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isActive(aliasConfig: AliasConfig): boolean {
    return aliasConfig.statusAi === StatusaiEnum.ACTIVE;
  }

  toggleActive(config: AliasConfig): void {
    this.configurationService.updateAliasConfig(
      {id: config.configId, statusai: config.statusAi === StatusaiEnum.ACTIVE ? StatusaiEnum.INACTIVE : StatusaiEnum.ACTIVE}
    ).subscribe((resultConfig: AliasConfig) => {
      if (resultConfig) {
        const index: number = this.aliasConfigs.findIndex((config: AliasConfig) => config.configId === resultConfig.configId);
        if (index > -1) {
          this.aliasConfigs[index] = resultConfig;
          this.dataSource = new MatTableDataSource<AliasConfig>(this.aliasConfigs);
          this.snackbar.open(
            `Successfully ${resultConfig.statusAi === StatusaiEnum.ACTIVE ? 'Activated' : 'Deactivated'} ${resultConfig.configDescription}.`,
            SnackbarActionEnum.SUCCESS);
        }
      }
    }, error => {
      this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
    });
  }

  createConfiguration(): void {
    this.dialog.open(CreateConfigurationComponent, {width: '50vw'}).afterClosed()
      .subscribe((dialogResult: AliasConfig) => {
      if (dialogResult) {
        this.aliasConfigs.push(dialogResult);
        this.dataSource = new MatTableDataSource<AliasConfig>(this.aliasConfigs);
      }
    });
  }

  updateConfiguration(data: AliasConfig): void {
    this.dialog.open(UpdateConfigurationComponent, {width: '50vw', data}).afterClosed()
      .subscribe((dialogResult: AliasConfig) => {
        if (dialogResult) {
          const index: number = this.aliasConfigs.findIndex((config: AliasConfig) => config.configId === dialogResult.configId);
          if (index > -1) {
            this.aliasConfigs[index] = dialogResult;
            this.dataSource = new MatTableDataSource<AliasConfig>(this.aliasConfigs);
          }
        }
      });
  }

  deleteConfiguration(aliasConfig: AliasConfig): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Configuration',
        message: `Are you sure you want to delete ${aliasConfig.configDescription}?`
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.configurationService.remove(aliasConfig.configId).subscribe(() => {
            const index: number = this.aliasConfigs.findIndex((config: AliasConfig) => config.configId === aliasConfig.configId);
            if (index > -1) {
              this.aliasConfigs.splice(index, 1);
              this.dataSource = new MatTableDataSource<AliasConfig>(this.aliasConfigs);
              this.snackbar.open(`Successfully deleted ${aliasConfig.configDescription}.`, SnackbarActionEnum.SUCCESS);
            }
          }, error => {
            this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
          })
        }
      });
  }
}
