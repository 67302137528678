import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Interface} from "../../../../../core/models/interface.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {InterfaceService} from "../../../../../core/services/interface.service";
import {MatDialogRef} from "@angular/material/dialog";
import {SnackbarActionEnum} from "../../../../../core/enums/snackbar-action.enum";
import {InterfaceTypeEnum} from "../../../../../core/enums/interface-type.enum";

@Component({
  selector: 'app-interface-create',
  templateUrl: './interface-create.component.html',
  styleUrls: ['./interface-create.component.scss']
})
export class InterfaceCreateComponent implements OnInit {
  form: FormGroup;
  createMode: boolean = true;
  interfaceObj: Interface;
  parentOrgId: number = 0;
  parentServiceId: number = 0;
  interfaceTypeOptions: [string, string][] = [];

  constructor(private formBuilder: FormBuilder, private snackbar: MatSnackBar, private interfaceService: InterfaceService, public dialogRef: MatDialogRef<InterfaceCreateComponent>) {
    this.form = this.formBuilder.group({
      id: [0],
      orgId:[this.parentOrgId],
      serviceId:[this.parentServiceId],
      interface: ['', [Validators.required, Validators.maxLength(255)]],
      interfaceDescription: ['', [Validators.required, Validators.maxLength(255)]],
      interfaceType: ['', [Validators.required, Validators.maxLength(255)]],
      interfaceName: ['', [Validators.required, Validators.maxLength(255)]],
    });
    this.interfaceTypeOptions = Object.entries(InterfaceTypeEnum);
  }

  ngOnInit() {
    if(this.createMode){
      this.form.controls['orgId'].setValue(this.parentOrgId);
      this.form.controls['serviceId'].setValue(this.parentServiceId);
    } else {
      this.form.patchValue(this.interfaceObj)
    }
  }

  createOrUpdateInterface() {
    if (this.createMode){
      this.interfaceService.create(this.form.getRawValue()).subscribe(
        (response: Interface) => {
          this.dialogRef.close(response);
          this.snackbar.open('Interface Successfully Created!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      );
    } else {
      this.interfaceService.update(this.form.getRawValue()).subscribe(
        (response: Interface) => {
          this.dialogRef.close(response);
          this.snackbar.open('Interface Updated Successfully!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      )
    }
  }

}
