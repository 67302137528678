<h2 mat-dialog-title>New Configuration</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
  <div class="flex flex-col p-2 w-full">
    <div class="flex flex-col min-h-[160px]">
      <b>Configuration References</b>
      <div class="flex flex-row space-x-2 py-2">
        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="flex flex-col">
          <mat-label>Organization</mat-label>
          <mat-select formControlName="orgId" (selectionChange)="onOrgSelect()">
            <mat-option *ngFor="let org of organizations" [value]="org.id">
              {{org.orgName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" *ngIf="form.get('orgId')?.value" class="flex flex-col">
          <mat-label>Interface</mat-label>
          <mat-select formControlName="interfaceId"(selectionChange)="setReferenceName('interfaceId', 'interface', interfaces)">
            <mat-option *ngFor="let _interface of interfaces" [value]="_interface.id">
              {{_interface.interfaceName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" *ngIf="form.get('orgId')?.value" class="flex flex-col">
          <mat-label>Location</mat-label>
          <mat-select formControlName="locId" (selectionChange)="onLocationSelect()">
            <mat-option *ngFor="let loc of locations" [value]="loc.id">
              {{loc.locName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-row space-x-2 pb-2">
        <mat-form-field subscriptSizing="dynamic" appearance="outline" *ngIf="form.get('locId')?.value" class="flex flex-col">
          <mat-label>Client</mat-label>
          <mat-select formControlName="clientId" (selectionChange)="setReferenceName('clientId', 'client', clients)">
            <mat-option *ngFor="let client of clients" [value]="client.id">
              {{client.clientName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" *ngIf="form.get('locId')?.value" class="flex flex-col">
          <mat-label>Service</mat-label>
          <mat-select formControlName="serviceId" (selectionChange)="setReferenceName('serviceId', 'service', services)">
            <mat-option *ngFor="let service of services" [value]="service.id">
              {{service.serviceName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" *ngIf="form.get('locId')?.value" class="flex flex-col">
          <mat-label>Environment</mat-label>
          <mat-select formControlName="environmentId" (selectionChange)="setReferenceName('environmentId', 'environment', environments)">
            <mat-option *ngFor="let environment of environments" [value]="environment.id">
              {{environment.environmentName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-col pb-2">
      <b>Configuration Info</b>
      <div class="flex gap-2 flex-wrap pt-2 items-center">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Description</mat-label>
          <input matInput formControlName="configDescription" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="flex flex-col">
          <mat-label>Log Level</mat-label>
          <mat-select formControlName="loglevel">
            <mat-option *ngFor="let logLevel of logLevels" [value]="logLevel">
              {{logLevel}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle
          color="primary"
          (toggleChange)="toggleActive()"
          [checked]="active"
        >Active
        </mat-slide-toggle>
      </div>
      <div class="flex gap-2 flex-wrap pt-2" formGroupName="alias">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Alias</mat-label>
          <input matInput formControlName="alias" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Alias Name</mat-label>
          <input matInput formControlName="aliasName" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Alias Description</mat-label>
          <input matInput formControlName="aliasDescription" />
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-col">
      <b>Notification Info</b>
      <div class="flex gap-2 flex-wrap pt-2">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Notify API URL</mat-label>
          <input matInput formControlName="notifyApiUrl" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Notify Message Subject</mat-label>
          <input matInput formControlName="notifyMsgSubject" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Notify Message Body</mat-label>
          <input matInput formControlName="notifyMsgBody" />
        </mat-form-field>
      </div>
      <div class="flex flex-col gap-2 pt-2 w-full">
        <app-delimited-list
          label="Email TOs"
          [delimitedString]="form.get('notifyEmailToList')?.getRawValue()"
          (updatedDelimitedString)="form.get('notifyEmailToList')?.setValue($event)"
        ></app-delimited-list>
        <app-delimited-list
          label="Email BCCs"
          [delimitedString]="form.get('notifyEmailBccList')?.getRawValue()"
          (updatedDelimitedString)="form.get('notifyEmailBccList')?.setValue($event)"
        ></app-delimited-list>
      </div>
      <div class="flex flex-col gap-2 pt-2 w-full">
        <app-delimited-list
          label="Email CCs"
          [delimitedString]="form.get('notifyEmailCcList')?.getRawValue()"
          (updatedDelimitedString)="form.get('notifyEmailCcList')?.setValue($event)"
        ></app-delimited-list>
        <app-delimited-list
          label="Text List Numbers"
          [delimitedString]="form.get('notifyTextList')?.getRawValue()"
          (updatedDelimitedString)="form.get('notifyTextList')?.setValue($event)"
        ></app-delimited-list>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div class="flex justify-end p-4">
  <div *ngIf="errorMessage" class="text-red">{{errorMessage}}</div>
  <button mat-button (click)="close()" class="mr-2">Cancel</button>
  <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createConfig()">Create</button>
</div>

