<fieldset class="border border-gray-500 rounded p-1 mt-2">
  <legend class="ml-1 p-1 text-gray-500 text-sm">{{label}}</legend>
  <mat-chip-listbox class="flex flex-wrap">
    <mat-chip (click)="showAdd()">
      <span class="cursor-pointer">
        <span class="font-bold">+</span> Add New {{label}}
      </span>
    </mat-chip>
    <mat-chip class="w-fit" *ngFor="let item of splitList">
      {{ item }} <button matChipRemove (click)="removeItem(item)"><mat-icon>cancel</mat-icon></button>
    </mat-chip>
  </mat-chip-listbox>
</fieldset>
<div *ngIf="showAddField" class="flex flex-col mt-2" [formGroup]="form">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>New {{label}} (separate by {{delimiterName}}s )</mat-label>
    <input matInput appAutoFocus formControlName="addInput" (keydown.enter)="addItem()"/>
  </mat-form-field>
  <div class="flex flex-row justify-end px-4 mt-1">
    <button mat-button class="mr-2" (click)="hideAdd()">Cancel</button>
    <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="addItem()">Add</button>
  </div>
</div>

