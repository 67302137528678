import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({name: 'whitespace'})
export class WhitespacePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(value: string): SafeHtml {
		if (!value) return '';
		let entityMap: any = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			"'": '&#39;',
			'/': '&#x2F;'
		};
		let text = String(value).replace(/[&<>"'\/]/g, function (s) {
			if (entityMap[s]) {
				return entityMap[s];
			}
			return s;
		});
		text = text.split('  ').join(' &nbsp');
		text = text.split('\n').join('<br />');
		return this.sanitizer.bypassSecurityTrustHtml(text);
	}
}
