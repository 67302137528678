import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../core/models/user.interface';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {UserService} from '../../../core/services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {TitleCasePipe} from '@angular/common';
import {UserTypeEnum} from '../../../core/enums/user-type.enum';
import {Role} from '../../../core/models/role.interface';
import {Organization} from "../../../core/models/organization.interface";

@Component({
	selector: 'app-users-list-detail',
	templateUrl: './users-list-detail.component.html',
	styleUrls: ['./users-list-detail.component.scss']
})
export class UsersListDetailComponent implements OnInit {
	@Input() user: User;
	@Input() roles: Role[];
  @Input() organizations: Organization[];
	form: FormGroup;
	userTypeOptions: string[] = [UserTypeEnum.EXTERNAL, UserTypeEnum.INTERNAL, UserTypeEnum.ADMIN];

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private userService: UserService,
		private snackbar: MatSnackBar,
		private titlecasePipe: TitleCasePipe
	) {
		this.form = this.formBuilder.group({
			id: [''],
			email: ['', Validators.required],
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			phone: [''],
			phoneExt: [''],
			roleId: ['', Validators.required],
			active: [''],
			type: ['', Validators.required],
      orgId: [],
		});
	}

	ngOnInit() {
		this.form.patchValue(this.user);
		this.form.get('id')?.setValue(this.user.id);
		this.form.get('active')?.setValue(this.user.active);
		this.form.get('roleId')?.setValue(this.user.roleId);
    this.form.get('orgId')?.setValue(this.user.orgId);
		this.form.disable();
	}

	onSubmit() {
		const formValue = this.form.getRawValue();
		this.userService.update(this.user.id, formValue).subscribe(
			(response) => {
				this.snackbar.open('User Info Updated!', SnackbarActionEnum.SUCCESS);
				this.userService.findAll();
				this.form.disable();
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}

	showToggleInactiveModal(activeColor: string): void {
		const userFullName = this.titlecasePipe.transform(this.user.firstName) + ' ' + this.titlecasePipe.transform(this.user.lastName);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '300px',
			disableClose: true,
			data: {
				message:
					activeColor == 'green'
						? 'Are you sure you want to deactivate ' + userFullName + '?'
						: 'Are you sure you want to activate ' + userFullName + '?'
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				const active = this.form.get('active');
				if (active?.value) {
					this.form.get('active')?.setValue(0);
					this.onSubmit();
				} else {
					this.form.get('active')?.setValue(1);
					this.onSubmit();
				}
			} else {
				return;
			}
		});
	}

	deleteUser(): void {
		const userFullName = this.titlecasePipe.transform(this.user.firstName) + ' ' + this.titlecasePipe.transform(this.user.lastName);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '300px',
			disableClose: true,
			data: {
				message: 'Are you sure you want to delete ' + userFullName + '?'
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.userService.remove(this.user.id).subscribe((response) => {
					this.userService.findAll();
					this.snackbar.open('User Deleted!', SnackbarActionEnum.SUCCESS);
				});
			}
		});
	}
}
