import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {RolesService} from '../../../core/services/roles.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-create-role',
	templateUrl: './create-role.component.html',
	styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent {
	form: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialogRef: MatDialogRef<CreateRoleComponent>,
		private roleService: RolesService
	) {
		this.form = this.formBuilder.group({
			name: ['', [Validators.required, Validators.maxLength(100)]],
			description: ['', Validators.maxLength(256)]
		});
	}

	createRole() {
		this.roleService.create(this.form.getRawValue()).subscribe(
			(response) => {
				this.dialogRef.close();
				this.snackbar.open('Role Successfully Created!', SnackbarActionEnum.SUCCESS);
				this.roleService.findAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}
}
