import {Component, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from "../core/services/dashboard.service";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels, ApexFill, ApexLegend,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis, ChartComponent
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: ChartOptions;

  loading: boolean = true;

  constructor(private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.getDashboard();
  }

  getDashboard() {
    this.loading = true;
    this.dashboardService.getDashboard().subscribe(response => {
      const alias = response.successRates.map(rate => rate.alias);
      const success = response.successRates.map(rate => rate.success);
      const failure = response.successRates.map(rate => rate.failure);

      this.chartOptions = {
        series: [
          {
            name: "Success",
            data: success
          },
          {
            name: "Failure",
            data: failure
          }
        ],
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              //hideOverflowingLabels: false,
              total: {
                enabled: true
              }
            }
          }
        },
        xaxis: {
          type: "category",
          categories: alias,
          //tickPlacement: 'on'
        },
        legend: {
          position: "right",
          offsetY: 40,
          markers: {
            fillColors: ['#00ff00', '#ff0000']
          }
        },
        fill: {
          colors: ['#00ff00', '#ff0000'],
          opacity: 1
        },
        dataLabels: {}
      };

      this.loading = false;
    });
  }


}
