import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup, FormGroupDirective, ValidationErrors} from '@angular/forms';
import {distinctUntilChanged, Subject, takeUntil, throttleTime} from 'rxjs';

interface AllValidationErrors {
	controlName: string;
	errorName: string;
	errorValue: any;
}

@Component({
	selector: 'app-validation-errors',
	templateUrl: './validation-errors.component.html',
	styleUrls: ['./validation-errors.component.scss']
})
export class ValidationErrorsComponent implements OnInit {
	form: FormGroup | undefined;
	errors: AllValidationErrors[] = [];

	private destroy$ = new Subject();

	constructor(private rootFormGroup: FormGroupDirective) {}

	ngOnInit() {
		this.form = this.rootFormGroup.form as FormGroup;
		if (this.form) {
			this.form.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((val) => {
				if (this.form) {
					this.calculateErrors(this.form);
				}
			});
		}
	}

	calculateErrors(form: FormGroup | FormArray) {
		this.errors = [];
		Object.keys(form.controls).forEach((field) => {
			const control = form.get(field);
			if (control instanceof FormGroup || control instanceof FormArray) {
				this.errors = this.errors.concat(this.calculateErrors(control));
				return;
			}

			const controlErrors: ValidationErrors | null | undefined = control?.errors;
			if (controlErrors) {
				Object.keys(controlErrors).forEach((keyError) => {
					this.errors.push({
						controlName: field,
						errorName: keyError,
						errorValue: controlErrors[keyError]
					});
				});
			}
		});

		// This removes duplicates
		this.errors = this.errors.filter(
			(error, index, self) =>
				self.findIndex((t) => {
					return t.controlName === error.controlName && t.errorName === error.errorName;
				}) === index
		);

		return this.errors;
	}
}
