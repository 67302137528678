<mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>
<div class="card" *ngIf="!loading">
  <h2>Environment</h2>
  <!--Environment info-->
  <div class="flex flex-wrap">
    <div *ngFor="let item of environment | keyvalue;" class="w-1/3">
      <p><strong>{{ item.key }}:</strong> {{ item.value }}</p>
    </div>
  </div>
</div>
