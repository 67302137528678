import {Component, OnInit, ViewChild} from '@angular/core';
import {OrganizationService} from "../../core/services/organization.service";
import {Organization} from "../../core/models/organization.interface";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {OrganizationCreateComponent} from "../organization/organization-create/organization-create.component";
import {take} from "rxjs";
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-organization',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  sort: MatSort;
  dataSource: MatTableDataSource<Organization> = new MatTableDataSource<Organization>();
  columnsToDisplay: string[] = ['name', 'contact', 'actions'];
  loading: boolean = true;
  organizations: Organization[];

  constructor(public organizationService: OrganizationService, public dialog: MatDialog, private snackbar: MatSnackBar){}

  ngOnInit(){
    this.organizationService.findAll().pipe(take(1)).subscribe((response: Organization[]) => {
      this.organizations = response;
      this.dataSource = new MatTableDataSource<Organization>(this.organizations)
      this.loading = false;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(OrganizationCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.afterClosed().subscribe((createdOrg: Organization) => {
      if (createdOrg){
        //update organization array with new value
        this.organizations.push(createdOrg);
        this.dataSource = new MatTableDataSource<Organization>(this.organizations);
      }
    })

  }

  openUpdateModal(event: Event, organization: Organization){
    event.stopPropagation();
    const dialogRef = this.dialog.open(OrganizationCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.createMode = false;
    dialogRef.componentInstance.organization = organization;
    dialogRef.afterClosed().subscribe((updatedOrg: Organization) => {
      if (updatedOrg) {
        //update organization array with new value
        const index = this.organizations.findIndex((org: Organization) => org.id === updatedOrg.id);
        this.organizations[index] = updatedOrg;
        this.dataSource = new MatTableDataSource<Organization>(this.organizations);
      }
    })
  }

  deleteOrganization(org: Organization): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Configuration',
        message: `Are you sure you want to delete ${org.orgName}?`
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.organizationService.remove(org.id).subscribe(() => {
            const index: number = this.organizations.findIndex((organization: Organization) => organization.id === org.id);
            if (index > -1) {
              this.organizations.splice(index, 1);
              this.dataSource = new MatTableDataSource<Organization>(this.organizations);
              this.snackbar.open(`Successfully deleted ${org.orgName}.`, SnackbarActionEnum.SUCCESS);
            }
          }, error => {
            this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
          })
        }
      });
  }
}
