<mat-drawer-container id="sidenav-container" autosize>
  <mat-drawer #drawer class="sidenav" mode="side">
    		<div>
    			<div (click)="navigate(data)" *ngFor="let data of navOptions" class="hover:bg-gray rounded p-2 cursor-pointer">
    				<div class="flex items-center">
    					<mat-icon class="cursor-pointer">{{ data.icon }}</mat-icon>
    					<span class="text-md md:text-lg ml-2">{{ data.text }}</span>
    				</div>
    			</div>
    		</div>
  </mat-drawer>

  <mat-drawer-content>
    		<app-main-toolbar (sideNavToggle)="drawer.toggle()"></app-main-toolbar>
        <app-breadcrumbs></app-breadcrumbs>
    		<router-outlet></router-outlet>
        <app-footer></app-footer>
  </mat-drawer-content>

</mat-drawer-container>
