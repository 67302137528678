import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Location} from '../models/location.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  baseUrl: string = `${environment.url}/location`;
  constructor(private http: HttpClient) {}

  create(location: Location): Observable<Location> {
    return this.http.post<Location>(this.baseUrl, location);
  }

  findAll(orgId?: number): Observable<Location[]> {
    let params: HttpParams = new HttpParams();

    if (orgId) {
      params = params.append('orgId', orgId);
    }
    return this.http.get<Location[]>(this.baseUrl, {params});
  }

  findOne(id: number): Observable<Location> {
    return this.http.get<Location>(`${this.baseUrl}/${id}`);
  }

  update(location: Location): Observable<Location> {
    return this.http.put<Location>(`${this.baseUrl}/${location.id}`, location);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
