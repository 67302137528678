import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {MonitorLog} from "../models/monitor-log.interface";
import {MonitorLogResponse} from "../models/monitor-log-response.interface";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MonitorLogService {
  baseUrl: string = `${environment.url}/monitor-log`;
  constructor(private http: HttpClient) {}

  findAll(skip?: number, limit?: number, status?: string, beforeDate?: Date, afterDate?: Date, searchTerm?: string, orderBy?: 'ASC' | 'DESC'): Observable<MonitorLogResponse> {
    let params = new HttpParams();

    if (skip !== undefined) {
      params = params.set('skip', skip.toString());
    }

    if (limit) {
      params = params.set('limit', limit.toString());
    }

    if (status) {
      params = params.set('status', status);
    }

    if (beforeDate) {
      params = params.set('dateIsBefore', beforeDate.toISOString().substring(0,10));
    }

    if (afterDate) {
      params = params.set('dateIsAfter', afterDate.toISOString().substring(0,10));
    }

    if (searchTerm) {
      params = params.set('search', searchTerm);
    }
    if (orderBy) {
      params = params.set('orderBy', orderBy);
    }

    return this.http.get<MonitorLogResponse>(this.baseUrl, { params });
  }

  findOne(id: number): Observable<MonitorLog> {
    return this.http.get<MonitorLog>(`${this.baseUrl}/${id}`);
  }

  update(log: MonitorLog): Observable<MonitorLog> {
    return this.http.put<MonitorLog>(`${this.baseUrl}/${log.id}`, log);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
