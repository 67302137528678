import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './core/components/login/login.component';
import {AuthInterceptorProviders} from './core/interceptors/auth.interceptor';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AuthService} from './core/services/auth.service';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar} from '@angular/material/snack-bar';
import {CookieService} from 'ngx-cookie-service';
import {AppInitProvider, AppInitService} from './core/services/app-init.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SharedModule} from './shared/shared.module';
import {MatRadioModule} from '@angular/material/radio';
import {MainToolbarComponent} from './core/components/main-toolbar/main-toolbar.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {SideNavComponent} from './core/components/side-nav/side-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FooterComponent} from './core/components/footer/footer.component';
import {ChangePasswordRequestComponent} from './core/components/change-password-request/change-password-request.component';
import {FirstLoginResetPasswordComponent} from './core/components/first-login-reset-password/first-login-reset-password.component';
import {MaintenanceModule} from './maintenance/maintenance.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AccountComponent} from "./core/components/account/account.component";
import {ClientService} from './core/services/client.service';
import {ConfigurationService} from './core/services/configuration.service';
import {EndpointService} from './core/services/endpoint.service';
import {InterfaceService} from './core/services/interface.service';
import {LocationService} from './core/services/location.service';
import {OrganizationService} from './core/services/organization.service';
import {OrganizationModule} from "./organization/organization.module";
import {ServiceService} from "./core/services/service.service";
import {MatGridListModule} from "@angular/material/grid-list";
import {ConfigurationModule} from './configuration/configuration.module';
import { BreadcrumbsComponent } from './core/components/breadcrumbs/breadcrumbs.component';
import {MonitorLogModule} from "./monitor-log/monitor-log.module";
import { ClipboardModule } from 'ngx-clipboard';
import {DashboardService} from "./core/services/dashboard.service";
import { NgApexchartsModule } from "ng-apexcharts";

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		MainToolbarComponent,
		SideNavComponent,
		FooterComponent,
		FirstLoginResetPasswordComponent,
		ChangePasswordRequestComponent,
    DashboardComponent,
    AccountComponent,
    BreadcrumbsComponent
	],
	imports: [
    OrganizationModule,
    ConfigurationModule,
    MonitorLogModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatCardModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		MatSortModule,
		MatIconModule,
		MatTableModule,
		MatPaginatorModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatButtonModule,
		MatAutocompleteModule,
		SharedModule,
		MatRadioModule,
		MatMenuModule,
		MatBadgeModule,
		MatSidenavModule,
		MaintenanceModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatSelectModule,
		MatListModule,
		MatTooltipModule,
    MatCheckboxModule,
    MatGridListModule,
    ClipboardModule,
    NgApexchartsModule
    ],
	providers: [
		AppComponent,
		AuthService,
		AuthInterceptorProviders,
		MatSnackBar,
		CookieService,
		AppInitProvider,
		AppInitService,
    ClientService,
    ConfigurationService,
    EndpointService,
    InterfaceService,
    LocationService,
    OrganizationService,
    ServiceService,
    DashboardService,
		{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3500}}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
