import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Location} from "../../../core/models/location.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LocationService} from "../../../core/services/location.service";
import {MatDialogRef} from "@angular/material/dialog";
import {SnackbarActionEnum} from "../../../core/enums/snackbar-action.enum";
import {ValidatorRegexEnum} from "../../../core/enums/validator-regex.enum";


@Component({
  selector: 'app-location-create',
  templateUrl: './location-create.component.html',
  styleUrls: ['./location-create.component.scss']
})
export class LocationCreateComponent implements OnInit {
  form: FormGroup;
  createMode: boolean = true;
  location: Location;
  parentOrgId: number = 0;

  constructor(private formBuilder: FormBuilder, private snackbar: MatSnackBar, private locationService: LocationService, public dialogRef: MatDialogRef<LocationCreateComponent>) {
    this.form = this.formBuilder.group({
      id: [0],
      orgId:[this.parentOrgId],
      locName: ['', [Validators.required, Validators.maxLength(255)]],
      locAddr1: ['', [Validators.required, Validators.maxLength(255)]],
      locAddr2: ['', [Validators.maxLength(255)]],
      locCity: ['', [Validators.required, Validators.maxLength(50)]],
      locState: ['', [Validators.required, Validators.maxLength(10)]],
      locPostal: ['', [Validators.required, Validators.maxLength(10)]],
      locCtry: ['', [Validators.required, Validators.maxLength(10)]],
      repName: ['', [Validators.required, Validators.maxLength(255)]],
      repEmail: ['', [Validators.maxLength(255), Validators.pattern(ValidatorRegexEnum.EMAIL)]],
      repPhone: ['', [Validators.pattern(ValidatorRegexEnum.PHONE)]],
      repNotes: ['', [Validators.maxLength(255)]],
      repFax: ['', [Validators.pattern(ValidatorRegexEnum.PHONE)]],
    });
  }

  ngOnInit() {
    if(this.createMode){
      this.form.controls['orgId'].setValue(this.parentOrgId);
    } else {
      this.form.patchValue(this.location)
    }
  }

  createOrUpdateLocation() {
    if (this.createMode){
      this.locationService.create(this.form.getRawValue()).subscribe(
        (response: Location) => {
          this.dialogRef.close(response);
          this.snackbar.open('Location Successfully Created!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      );
    } else {
      this.locationService.update(this.form.getRawValue()).subscribe(
        (response: Location) => {
          this.dialogRef.close(response);
          this.snackbar.open('Location Updated Successfully!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      )
    }
  }
}
