<div class="h-12 w-full flex mt-2 toolbar relative pb-2">
<!--Sidenav Button-->
  <div *ngIf="isInternal()" (click)="onHamburgerClick()" class="cursor-pointer" [ngClass]="isHamburger ? 'p-4' : 'p-2'">
    <div class="icon-bar" [@hamburgerX]="isHamburger ? 'hamburger' : 'topX'"></div>
    <div class="icon-bar" [@hamburgerX]="isHamburger ? 'hamburger' : 'hide'"></div>
    <div class="icon-bar" [@hamburgerX]="isHamburger ? 'hamburger' : 'bottomX'"></div>
  </div>
<!--Logo-->
	<img src="/assets/kogent-logo.png" alt="Kogent Logo" class="h-12" />
	<div
		(click)="navigate(data)"
		*ngFor="let data of navOptions"
		class="ml-4 flex items-center hover:bg-gray-100 hover:rounded-lg ml-4 p-2 cursor-pointer"
	>
		<div class="flex items-center">
			<span class="w-max text-md md:text-lg text-gray">{{ data.text }}</span>
		</div>
	</div>
	<div class="w-full"></div>
<!--Menus-->
	<button mat-icon-button [matMenuTriggerFor]="adminOptions" *ngIf="isAdmin()">
		<mat-icon>settings</mat-icon>
	</button>
	<mat-menu #adminOptions="matMenu">
		<button *ngIf="isAdmin()" routerLink="/users" mat-menu-item>Users</button>
		<button *ngIf="isAdmin()" routerLink="/roles" mat-menu-item>Roles</button>
		<button *ngIf="!isAdmin()" disabled mat-menu-item>No options available...</button>
	</mat-menu>

	<button mat-icon-button [matMenuTriggerFor]="userOptions" class="mr-4">
		<div
			class="h-8 w-8 relative -top-1 -left-1 text-white text-sm items-center justify-center flex rounded-[50%]"
			[style.background-color]="getColor()"
		>
			{{ getInitials() }}
		</div>
	</button>
	<mat-menu #userOptions="matMenu">
    <button routerLink="/account" mat-menu-item>My Account</button>
		<button (click)="logout()" mat-menu-item>Logout</button>
	</mat-menu>
</div>
