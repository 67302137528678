<h2 mat-dialog-title>{{createMode ? 'Create' : 'Update'}} Organization</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
  <div>
    <b>Organization Info</b>
    <div class="flex gap-2 flex-wrap pt-2">
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Name</mat-label>
        <input matInput formControlName="orgName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Address</mat-label>
        <input matInput formControlName="orgAddr1" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Address 2</mat-label>
        <input matInput formControlName="orgAddr2" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>City</mat-label>
        <input matInput formControlName="orgCity" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>State</mat-label>
        <input matInput formControlName="orgState" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Postal</mat-label>
        <input matInput formControlName="orgPostal" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Country</mat-label>
        <input matInput formControlName="orgCtry" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Org Tax ID</mat-label>
        <input matInput formControlName="orgTaxId" />
      </mat-form-field>
    </div>
  </div>
  <div class="mt-2">
    <b>Contact Info</b>
    <div class="flex gap-2 flex-wrap pt-2">
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Contact Name</mat-label>
        <input matInput formControlName="contactName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Contact Email</mat-label>
        <input matInput formControlName="contactEmail" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Contact Phone</mat-label>
        <input matInput formControlName="contactPhone" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Contact Notes</mat-label>
        <input matInput formControlName="contactNotes" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Contact Fax</mat-label>
        <input matInput formControlName="contactFax" />
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<div class="flex justify-end p-4">
  <button mat-button [mat-dialog-close]="false" class="mr-2">Cancel</button>
  <button [disabled]="form.invalid || (!createMode && form.pristine)" mat-raised-button color="primary" (click)="createOrganization()">{{createMode ? 'Create' : 'Update'}}</button>
</div>
