import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SnackbarActionEnum} from '../../enums/snackbar-action.enum';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ValidatorRegexEnum} from '../../enums/validator-regex.enum';

@Component({
	selector: 'app-change-password-request',
	templateUrl: './change-password-request.component.html',
	styleUrls: ['./change-password-request.component.scss']
})
export class ChangePasswordRequestComponent {
	hide = true;
	errorMessages: string[] = [];
	requestSent: boolean = false;

	requestForm = new FormGroup({
		username: new FormControl('', [Validators.pattern(ValidatorRegexEnum.EMAIL), Validators.required])
	});

	resetForm = this.fb.group(
		{
			password: new FormControl('', [Validators.required]),
			confirmPassword: new FormControl('', [Validators.required]),
			confirmationCode: new FormControl('', [Validators.required])
		},
		{validator: this.passwordsMatch}
	);

	constructor(private authService: AuthService, private snackbar: MatSnackBar, private fb: FormBuilder, private router: Router) {}

	ngOnInit() {
		if (this.authService.userLoggedIn) {
			this.router.navigate(['/']);
		}
	}
	passwordsMatch(form: FormGroup) {
		if (form.value.password !== form.value.confirmPassword) {
			return {passwordsMatch: true};
		}
		return null;
	}

	resetRequest() {
		const username = this.requestForm.getRawValue().username;
		this.authService.requestPasswordReset(<string>username).subscribe(
			(response) => {
				this.requestSent = true;
			},
			(error) => {
				this.snackbar.open('Issue Requesting Password Request', SnackbarActionEnum.ERROR);
			}
		);
	}

	resetPassword() {
		const payload = this.resetForm.getRawValue();
		payload.username = this.requestForm.getRawValue().username;
		this.authService.resetUserPassword(payload).subscribe(
			(response) => {
				this.snackbar.open('Password Reset', SnackbarActionEnum.SUCCESS);
				this.router.navigate(['/login']);
			},
			(error) => {
				if (Array.isArray(error.error.message)) {
					this.errorMessages = error.error.message;
				} else {
					this.errorMessages = [error.error.message];
				}
				this.snackbar.open('Issue Resetting Password', SnackbarActionEnum.ERROR);
			}
		);
	}
}
