import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Role} from '../../../core/models/role.interface';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RolesService} from '../../../core/services/roles.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {TitleCasePipe} from '@angular/common';

@Component({
	selector: 'app-roles-list-detail',
	templateUrl: './roles-list-detail.component.html',
	styleUrls: ['./roles-list-detail.component.scss']
})
export class RolesListDetailComponent implements OnInit {
	@Input() role: Role;
	form: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private rolesService: RolesService,
		private snackbar: MatSnackBar,
		private titlecasePipe: TitleCasePipe
	) {
		this.form = this.formBuilder.group({
			id: [''],
			name: ['', Validators.required],
			description: ['']
		});
	}
	ngOnInit() {
		this.form.patchValue(this.role);
		this.form.disable();
	}

	onSubmit() {
		const formValue = this.form.getRawValue();
		this.rolesService.update(formValue).subscribe(
			(response) => {
				this.snackbar.open('Transit Time Updated!', SnackbarActionEnum.SUCCESS);
				this.rolesService.findAll();
				this.form.disable();
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}

	deleteRole() {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '300px',
			disableClose: true,
			data: {
				message: 'Are you sure you want to delete the ' + this.titlecasePipe.transform(this.role.name) + ' role?'
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.rolesService.remove(this.role.id).subscribe((response) => {
					this.rolesService.findAll();
					this.snackbar.open('Role Deleted!', SnackbarActionEnum.SUCCESS);
				});
			}
		});
	}
}
