import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MonitorLog} from "../../core/models/monitor-log.interface";
import {MatDialog} from "@angular/material/dialog";
import {MonitorLogService} from "../../core/services/monitor-log.service";
import { MatPaginator } from '@angular/material/paginator';
import {MonitorLogResponse} from "../../core/models/monitor-log-response.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subscription, take} from "rxjs";
import {MonitorLogComponent} from "../monitor-log/monitor-log.component";

@Component({
  selector: 'app-monitor-log',
  templateUrl: './monitor-log-list.component.html',
  styleUrls: ['./monitor-log-list.component.scss']
})
export class MonitorLogListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filterForm: FormGroup;
  sort: MatSort;
  dataSource: MatTableDataSource<MonitorLog> = new MatTableDataSource<MonitorLog>();
  columnsToDisplay: string[] = ['createdTs', 'status', 'orgName', 'locName', 'alias', 'payload', 'client', 'service', 'sow'];
  loading: boolean = true;
  monitorLogResponse: MonitorLogResponse = {} as MonitorLogResponse;
  paginatorSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private monitorLogService: MonitorLogService) {
    this.filterForm = this.formBuilder.group({
      searchTerm: [''],
      startDate: [''],
      endDate: [''],
      status: [''],
      orderBy: ['DESC']
    });
  }

  ngOnInit() {
    this.searchForLogs();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginatorSubscription = this.paginator.page.subscribe(() => this.searchForLogs());
  }

  searchForLogs(){
    let skip = 0;
    let limit = 50;

    if(this.paginator){
      skip = this.paginator.pageIndex * this.paginator.pageSize;
      limit = this.paginator.pageSize;
    }

    this.loading = true;
    this.monitorLogService.findAll(
      skip,
      limit,
      this.filterForm.get('status')?.value,
      this.filterForm.get('endDate')?.value,
      this.filterForm.get('startDate')?.value,
      this.filterForm.get('searchTerm')?.value,
      this.filterForm.get('orderBy')?.value,
    ).pipe(take(1)).subscribe( (logsResponse: MonitorLogResponse) => {
      this.monitorLogResponse = logsResponse;
      if (this.paginator.length > logsResponse.total) {
        this.paginator.length = logsResponse.total;
        this.paginator.firstPage();
      }
      this.dataSource = new MatTableDataSource<MonitorLog>(logsResponse.results);
      this.loading = false;
    })
  }

  openMonitorLogModal(log: MonitorLog){
    const dialogRef = this.dialog.open(MonitorLogComponent, {
      width: '90vw'
    })
    dialogRef.componentInstance.monitorLog = log;
  }

  openPayloadComponent(event: Event, log: MonitorLog){
    event.stopPropagation();
    const dialogRef = this.dialog.open(MonitorLogComponent, {
      width: '90vw'
    })
    dialogRef.componentInstance.payloadMode = true;
    dialogRef.componentInstance.monitorLog = log;
  }

  resetDatePicker(){
    this.filterForm.controls['startDate'].setValue('')
    this.filterForm.controls['endDate'].setValue('')
  }

  ngOnDestroy(){
    this.paginatorSubscription.unsubscribe();
  }
}
