<h2 mat-dialog-title>{{payloadMode ? 'Payload for ' : (monitorLog['createdTs'] | date: 'short') + '&nbsp;&nbsp;-&nbsp;&nbsp;'}}{{monitorLog['locName']}}</h2>
<mat-dialog-content class="m-2" >
  <div *ngIf="!payloadMode" class="flex flex-wrap">
    <div *ngFor="let item of monitorLog | keyvalue;" class="w-1/2">
      <p><strong>{{ item.key }}:</strong> {{ item.value }}</p>
    </div>
  </div>
  <div *ngIf="payloadMode">
    {{monitorLog['apiPayload']}}
  </div>
</mat-dialog-content>
<div class="flex justify-end items-center p-4 gap-2">
  <button *ngIf="payloadMode" mat-raised-button color="primary" ngxClipboard [cbContent]="monitorLog['apiPayload']" (click)="copyAlert()"><mat-icon>content_copy</mat-icon>Copy</button>
  <button mat-raised-button color="primary" mat-dialog-close="true">Close</button>
</div>
