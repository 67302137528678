<h2 mat-dialog-title>{{createMode ? 'Create' : 'Update'}} Interface</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
  <div>
    <b>Interface Info</b>
    <div class="flex gap-2 flex-wrap pt-2">
      <mat-form-field subscriptSizing="dynamic" class="w-full">
        <mat-label>Interface</mat-label>
        <input matInput formControlName="interface" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="w-full">
        <mat-label>Name</mat-label>
        <input matInput formControlName="interfaceName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="w-full">
        <mat-label>Description</mat-label>
        <input matInput formControlName="interfaceDescription" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="w-full">
        <mat-label>Type</mat-label>
        <mat-select formControlName="interfaceType">
          <mat-option *ngFor="let option of interfaceTypeOptions" [value]="option[0]">
            {{option[1]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

</mat-dialog-content>
<div class="flex justify-end p-4">
  <button mat-button [mat-dialog-close]="false" class="mr-2">Cancel</button>
  <button [disabled]="form.invalid || (!createMode && form.pristine)" mat-raised-button color="primary" (click)="createOrUpdateInterface()">{{createMode ? 'Create' : 'Update'}}</button>
</div>
