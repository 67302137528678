import {Injectable, Pipe, PipeTransform} from '@angular/core';

export type SortOrder = 'asc' | 'desc';

@Injectable()
@Pipe({
	name: 'sort'
})
export class SortPipe implements PipeTransform {
	transform(value: any[], sortOrder: SortOrder | string = 'asc', sortKey1?: string, sortKey2?: string): any {
		sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

		if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) return value;
		let numberArray;
		let stringArray;

		if (!sortKey1) {
			numberArray = value.filter((item) => typeof item === 'number').sort();
			stringArray = value.filter((item) => typeof item === 'string').sort();
		} else {
			numberArray = value.filter((item) => typeof item[sortKey1] === 'number').sort((a, b) => a[sortKey1] - b[sortKey1]);
			stringArray = value
				.filter((item) => typeof item[sortKey1] === 'string')
				.sort((a, b) => {
					if (a[sortKey1].toLowerCase() < b[sortKey1].toLowerCase()) return -1;
					else if (a[sortKey1] > b[sortKey1]) return 1;
					else return 0;
				});
		}

		if (sortKey1 && sortKey2) {
			numberArray = value
				.filter((item) => typeof item[sortKey1] === 'number')
				.sort((a, b) => a[sortKey1][sortKey2] - b[sortKey1][sortKey2]);
			stringArray = value
				.filter((item) => typeof item[sortKey1][sortKey2] === 'string')
				.sort((a, b) => {
					if (a[sortKey1][sortKey2].toLowerCase() < b[sortKey1][sortKey2].toLowerCase()) return -1;
					else if (a[sortKey1][sortKey2] > b[sortKey1][sortKey2]) return 1;
					else return 0;
				});
		}
		const sorted = numberArray.concat(stringArray);
		return sortOrder === 'asc' ? sorted : sorted.reverse();
	}
}
