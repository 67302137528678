import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Interface} from '../models/interface.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterfaceService {
  baseUrl: string = `${environment.url}/interface`;
  constructor(private http: HttpClient) {}

  create(_interface: Interface): Observable<Interface> {
    return this.http.post<Interface>(this.baseUrl, _interface);
  }

  findAll(orgId?: number): Observable<Interface[]> {
    let params: HttpParams = new HttpParams();

    if (orgId) {
      params = params.append('orgId', orgId);
    }

    return this.http.get<Interface[]>(this.baseUrl, {params});
  }

  findOne(id: number): Observable<Interface> {
    return this.http.get<Interface>(`${this.baseUrl}/${id}`);
  }

  update(_interface: Interface): Observable<Interface> {
    return this.http.put<Interface>(`${this.baseUrl}/${_interface.id}`, _interface);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
