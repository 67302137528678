import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Environment} from '../models/environment.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  baseUrl: string = `${environment.url}/environment`;
  constructor(private http: HttpClient) {}

  create(environment: Environment): Observable<Environment> {
    return this.http.post<Environment>(this.baseUrl, environment);
  }

  findAll(locId?: number): Observable<Environment[]> {
    let params: HttpParams = new HttpParams();

    if (locId) {
      params = params.append('locId', locId);
    }

    return this.http.get<Environment[]>(this.baseUrl, {params});
  }

  findOne(id: number): Observable<Environment> {
    return this.http.get<Environment>(`${this.baseUrl}/${id}`);
  }

  update(environment: Environment): Observable<Environment> {
    return this.http.put<Environment>(`${this.baseUrl}/${environment.id}`, environment);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
