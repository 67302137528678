import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {User} from '../models/user.interface';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	usersSubject: Subject<User[]> = new Subject();
	constructor(private http: HttpClient) {}

	findAll(roles: boolean = false): void {
		let params: HttpParams = new HttpParams();

		if (roles) {
			params = params.append('roles', 'true');
		}

		this.http.get<any>(`${environment.url}/users`, {params}).subscribe((users) => {
			this.usersSubject.next(users);
		});
	}

	findOne(id: number): Observable<any> {
		return this.http.get<any>(`${environment.url}/users/${id}`);
	}

	findAllExternal(): Observable<any> {
		return this.http.get<any>(`${environment.url}/users/external`);
	}

	update(id: number, user: Partial<User>): Observable<any> {
		return this.http.patch<any>(`${environment.url}/users/${user.id}`, user);
	}

	create(user: Partial<User>): Observable<User> {
		return this.http.post<any>(`${environment.url}/users`, user);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${environment.url}/users/${id}`);
	}

	getUserColor(name: string | undefined): string {
		if (!name) {
			return '';
		}
		const color = name.split('').reduce((acc, curr) => acc + curr.charCodeAt(0), 0) % 360;
		return `hsl(${color}, 70%, 50%)`;
	}
}
