import { Component } from '@angular/core';
import {Client} from "../../../core/models/client.interface";
import {ClientService} from "../../../core/services/client.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent {
  loading: boolean = true;
  // client: Client;
  client: Record<any,any>;
  constructor(public clientService: ClientService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.clientService.findOne( params['clientId']).subscribe(response => {
        this.client = {...response};
        this.loading = false;
      })
    });
  }
}
