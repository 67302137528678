<mat-card class="w-36 h-48 m-4">
	<mat-card-header class="text-gray-500">
		{{ user.role?.name ? user.role?.name : 'N/A' }}
	</mat-card-header>
	<mat-card-content>
		<div class="mt-3 h-full flex flex-col justify-center text-center items-center">
			<div
				class="h-16 w-16 text-white text-lg items-center justify-center flex rounded-[50%]"
				[style.background-color]="getColor(fullName())"
			>
				{{ getInitials(fullName()) }}
			</div>
			<span class="font-semibold text-md mt-1">{{ fullName() }}</span>
		</div>
		<div *ngIf="!readOnly" class="absolute bottom-0 right-0">
			<mat-checkbox color="primary" (change)="userSelected($event)" [(ngModel)]="user.checked"></mat-checkbox>
		</div>
	</mat-card-content>
</mat-card>
