import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Client} from "../../../core/models/client.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ClientService} from "../../../core/services/client.service";
import {MatDialogRef} from "@angular/material/dialog";
import {SnackbarActionEnum} from "../../../core/enums/snackbar-action.enum";

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit {
  form: FormGroup;
  createMode: boolean = true;
  client: Client;
  parentOrgId: number = 0;
  parentLocId: number = 0;

  constructor(private formBuilder: FormBuilder, private snackbar: MatSnackBar, private clientService: ClientService, public dialogRef: MatDialogRef<ClientCreateComponent>) {
    this.form = this.formBuilder.group({
      id: [0],
      orgId:[this.parentOrgId],
      locId:[this.parentLocId],
      client: ['', [Validators.required, Validators.maxLength(255)]],
      clientName: ['', [Validators.required, Validators.maxLength(255)]],
      clientDescription: ['', [Validators.maxLength(255)]],
    });
  }

  ngOnInit() {
    if(this.createMode){
      this.form.controls['orgId'].setValue(this.parentOrgId);
      this.form.controls['locId'].setValue(this.parentLocId);
    } else {
      this.form.patchValue(this.client)
    }
  }

  createOrUpdateClient() {
    if (this.createMode){
      this.clientService.create(this.form.getRawValue()).subscribe(
        (response: Client) => {
          this.dialogRef.close(response);
          this.snackbar.open('Client Successfully Created!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      );
    } else {
      this.clientService.update(this.form.getRawValue()).subscribe(
        (response: Client) => {
          this.dialogRef.close(response);
          this.snackbar.open('Client Updated Successfully!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      )
    }
  }
}
