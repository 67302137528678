<section class="card scroll-table-card">
  <div class="flex justify-between">
    <h2>Services</h2>
    <button mat-mini-fab color="primary" (click)="openCreateModal()"><mat-icon>add</mat-icon></button>
  </div>
  <mat-form-field class="w-1/2 mb-2" color="primary" subscriptSizing="dynamic">
    <mat-label>Search</mat-label>
    <input
      id="user-searchbar"
      type="text"
      (keyup)="applyFilter($event)"
      placeholder="Search..."
      matInput
      #searchInput
    />
  </mat-form-field>

  <mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>
  <div class="max-h-[25vh] overflow-auto">
  <table mat-table [dataSource]="dataSource" class="w-full" matSort *ngIf="!loading">
    <!--Name Column-->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Name</th>
      <td mat-cell *matCellDef="let service">
        {{ service.serviceName }}
      </td>
    </ng-container>
    <!--Contact Column-->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Description</th>
      <td mat-cell *matCellDef="let service">
        {{ service.serviceDescription }}
      </td>
    </ng-container>
    <!--Actions Column-->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let service">
        <button mat-icon-button (click)="openUpdateModal($event, service)"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button (click)="$event.stopPropagation(); deleteService(service)"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let service; let i = dataIndex; columns: columnsToDisplay" class="cursor-pointer" [routerLink]="'service/' + service.id"></tr>
    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row flex" *matNoDataRow>
      <td class="mat-cell p-4" colspan="4" *ngIf="!loading">No data matching the filter "{{ searchInput.value }}"</td>
    </tr>
  </table>
  </div>
</section>
