import { Component } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarActionEnum} from "../../core/enums/snackbar-action.enum";

@Component({
  selector: 'app-monitor-log',
  templateUrl: './monitor-log.component.html',
  styleUrls: ['./monitor-log.component.scss']
})
export class MonitorLogComponent {
  payloadMode: boolean = false;
  monitorLog: Record<any,any>;
  constructor(private snackBar: MatSnackBar) {}
  copyAlert(){
    this.snackBar.open('Payload copied to clipboard!', SnackbarActionEnum.SUCCESS);
  }
}
