import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Configuration} from '../../../core/models/configuration.interface';
import {StatusaiEnum} from '../../../core/enums/statusai-enum';
import {LogLevelEnum} from '../../../core/enums/log-level.enum';
import {ConfigurationService} from '../../../core/services/configuration.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {AliasConfig} from '../../../core/models/alias-config.interface';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-configuration',
  templateUrl: './update-configuration.component.html',
  styleUrls: ['./update-configuration.component.scss']
})
export class UpdateConfigurationComponent implements OnInit {
  form: FormGroup;
  logLevels: LogLevelEnum[] = Object.values(LogLevelEnum);
  errorMessage: string;
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<UpdateConfigurationComponent>,
    private configurationService: ConfigurationService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: AliasConfig
  ) {
    this.form = this.formBuilder.group({
      id: [null],
      configDescription: ['', Validators.required],
      aliasName: [null],
      orgId: [null, Validators.required],
      locId: [null, Validators.required],
      clientId: [null, Validators.required],
      serviceId: [null, Validators.required],
      interfaceId: [null, Validators.required],
      environmentId: [null, Validators.required],
      client: [null],
      service: [null],
      interface: [null],
      environment: [null],
      statusai: [StatusaiEnum.ACTIVE],
      loglevel: [LogLevelEnum.FULL],
      notifyApiUrl: [null],
      notifyEmailToList: [''],
      notifyEmailCcList: [''],
      notifyEmailBccList: [''],
      notifyTextList: [''],
      notifyMsgSubject: [null],
      notifyMsgBody: [null],
      alias: this.formBuilder.group({
        id: [null],
        alias: [null, Validators.required],
        aliasName: [null],
        aliasDescription: [null],
        configId: [null],
      })
    });
  }

  ngOnInit() {
    this.configurationService.findOne(this.data.configId).subscribe((result: Configuration) => {
      this.form.patchValue(result);
    });
  }

  get active(): boolean {
    return this.form.get('statusai')?.value === StatusaiEnum.ACTIVE;
  }

  toggleActive(): void {
    this.form.get('statusai')?.setValue(this.active ? StatusaiEnum.INACTIVE : StatusaiEnum.ACTIVE);
  }

  close(): void {
    this.dialogRef.close();
  }

  updateConfig(): void {
    const formValue: Configuration = this.form.getRawValue();

    this.configurationService.updateAliasConfig(formValue).subscribe(
      (response: AliasConfig) => {
        this.dialogRef.close(response);
        this.snackbar.open('Configuration Successfully Created!', SnackbarActionEnum.SUCCESS);
      },
      (error) => {
        this.errorMessage = error?.error?.errorDetails?.driverError?.detail;
        this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
      }
    );
  }
}
