<h2 mat-dialog-title>Update Configuration</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
  <div class="flex flex-col p-2 w-full">
    <div class="flex flex-col pb-2">
      <b>Configuration Info</b>
      <div class="flex gap-2 flex-wrap pt-2 items-center">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Description</mat-label>
          <input matInput formControlName="configDescription" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="flex flex-col">
          <mat-label>Log Level</mat-label>
          <mat-select formControlName="loglevel">
            <mat-option *ngFor="let logLevel of logLevels" [value]="logLevel">
              {{logLevel}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle
          color="primary"
          (toggleChange)="toggleActive()"
          [checked]="active"
        >Active
        </mat-slide-toggle>
      </div>
      <div class="flex gap-2 flex-wrap pt-2" formGroupName="alias">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Alias</mat-label>
          <input matInput formControlName="alias" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Alias Name</mat-label>
          <input matInput formControlName="aliasName" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Alias Description</mat-label>
          <input matInput formControlName="aliasDescription" />
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-col">
      <b>Notification Info</b>
      <div class="flex gap-2 flex-wrap pt-2">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Notify API URL</mat-label>
          <input matInput formControlName="notifyApiUrl" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Notify Message Subject</mat-label>
          <input matInput formControlName="notifyMsgSubject" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Notify Message Body</mat-label>
          <input matInput formControlName="notifyMsgBody" />
        </mat-form-field>
      </div>
      <div class="flex flex-col gap-2 pt-2 w-full">
        <app-delimited-list
          label="Email TOs"
          [delimitedString]="form.get('notifyEmailToList')?.getRawValue()"
          (updatedDelimitedString)="form.get('notifyEmailToList')?.setValue($event)"
        ></app-delimited-list>
        <app-delimited-list
          label="Email BCCs"
          [delimitedString]="form.get('notifyEmailBccList')?.getRawValue()"
          (updatedDelimitedString)="form.get('notifyEmailBccList')?.setValue($event)"
        ></app-delimited-list>
      </div>
      <div class="flex flex-col gap-2 pt-2 w-full">
        <app-delimited-list
          label="Email CCs"
          [delimitedString]="form.get('notifyEmailCcList')?.getRawValue()"
          (updatedDelimitedString)="form.get('notifyEmailCcList')?.setValue($event)"
        ></app-delimited-list>
        <app-delimited-list
          label="Text List Numbers"
          [delimitedString]="form.get('notifyTextList')?.getRawValue()"
          (updatedDelimitedString)="form.get('notifyTextList')?.setValue($event)"
        ></app-delimited-list>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div class="flex justify-end p-4">
  <div *ngIf="errorMessage" class="text-red">{{errorMessage}}</div>
  <button mat-button (click)="close()" class="mr-2">Cancel</button>
  <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="updateConfig()">Update</button>
</div>

