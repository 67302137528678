import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'underscoreRemove'})
export class UnderscoreRemove implements PipeTransform {
	transform(value: string): string {
		if (!value) return '';
		const words = value.split('_');
		return words.join(' ');
	}
}
