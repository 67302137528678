<h2 mat-dialog-title>{{createMode ? 'Create' : 'Update'}} Client</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
  <div>
    <b>Client Info</b>
    <div class="flex gap-2 flex-wrap pt-2">
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Client</mat-label>
        <input matInput formControlName="client" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Name</mat-label>
        <input matInput formControlName="clientName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Description</mat-label>
        <input matInput formControlName="clientDescription" />
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<div class="flex justify-end p-4">
  <button mat-button [mat-dialog-close]="false" class="mr-2">Cancel</button>
  <button [disabled]="form.invalid || (!createMode && form.pristine)" mat-raised-button color="primary" (click)="createOrUpdateClient()">{{createMode ? 'Create' : 'Update'}}</button>
</div>
