import {Component, OnInit} from '@angular/core';
import {ConfigurationService} from "../../core/services/configuration.service";
import {Configuration} from "../../core/models/configuration.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {AliasConfig} from '../../core/models/alias-config.interface';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit{
  loading: boolean = false;
  aliasConfig: AliasConfig;
  constructor(public configurationService: ConfigurationService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.configurationService.findOneAliasConfig( params['id']).subscribe(response => {
        this.aliasConfig = response;
      })
    });
  }

  routeTo(destination: string): void {
    const baseUrl: string = `/organization/${this.aliasConfig.orgId}/location/${this.aliasConfig.locId}`;
    switch (destination) {
      case 'client':
        this.router.navigate([
          `${baseUrl}/client/${this.aliasConfig.clientId}`
        ]);
        break;
      case 'service':
        this.router.navigate([
          `${baseUrl}/service/${this.aliasConfig.serviceId}`
        ]);
        break;
      case 'environment':
        this.router.navigate([
          `${baseUrl}/environment/${this.aliasConfig.environmentId}`
        ]);
        break;
      case 'interface':
        this.router.navigate([
          `${baseUrl}/service/${this.aliasConfig.serviceId}/interface/${this.aliasConfig.interfaceId}`
        ]);
        break;
      default:
        console.error('No destination found.');
    }
  }

}
