import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatInputModule } from "@angular/material/input";
import { RouterLink } from "@angular/router";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { OrganizationComponent } from './organization/organization.component';
import { LocationListComponent } from './location/location-list/location-list.component';
import { InterfaceListComponent } from './location/service/interface/interface-list/interface-list.component';
import {  MatGridListModule} from "@angular/material/grid-list";
import { LocationComponent } from './location/location/location.component';
import { ClientComponent } from './location/client/client.component';
import { ClientListComponent } from './location/client-list/client-list.component';
import { ServiceComponent } from './location/service/service.component';
import { ServiceListComponent } from './location/service-list/service-list.component';
import { EnvironmentComponent } from './location/environment/environment.component';
import { EnvironmentListComponent } from './location/environment-list/environment-list.component';
import { InterfaceComponent } from './location/service/interface/interface/interface.component';
import { EndpointComponent } from './location/service/interface/endpoint/endpoint.component';
import { EndpointCreateComponent } from './location/service/interface/endpoint-create/endpoint-create.component';
import { EndpointListComponent } from './location/service/interface/endpoint-list/endpoint-list.component';
import { OrganizationCreateComponent } from './organization/organization-create/organization-create.component';
import { MatDialogModule } from "@angular/material/dialog";
import { ReactiveFormsModule } from "@angular/forms";
import { LocationCreateComponent } from './location/location-create/location-create.component';
import { InterfaceCreateComponent } from './location/service/interface/interface-create/interface-create.component';
import { EnvironmentCreateComponent } from './location/environment-create/environment-create.component';
import { ServiceCreateComponent } from './location/service-create/service-create.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { ClientCreateComponent } from "./location/client-create/client-create.component";
import {MatSelectModule} from "@angular/material/select";


@NgModule({
  declarations: [
    OrganizationListComponent,
    OrganizationComponent,
    LocationListComponent,
    InterfaceListComponent,
    LocationComponent,
    ClientComponent,
    ClientListComponent,
    ServiceComponent,
    ServiceListComponent,
    EnvironmentComponent,
    EnvironmentListComponent,
    InterfaceComponent,
    EndpointComponent,
    EndpointListComponent,
    OrganizationCreateComponent,
    LocationCreateComponent,
    InterfaceCreateComponent,
    ClientCreateComponent,
    EnvironmentCreateComponent,
    ServiceCreateComponent,
    EndpointCreateComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    RouterLink,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule
  ]
})
export class OrganizationModule { }
