<div class="m-auto flex flex-row justify-center w-full" [formGroup]="accountFormGroup">
  <div class="reset">
    <h1 class="pt-8 pl-8">Change Password</h1>
    <div class="flex flex-col p-8 h-[300px]" formGroupName="changePasswordFormGroup">
      <div class="flex-row flex w-full h-full">
        <div>
          <mat-form-field class="w-full">
            <mat-label>Password</mat-label>
            <input name="password" autocomplete="current-password" formControlName="password" matInput [type]="hidePassword ? 'password' : 'text'" />
            <mat-icon class="cursor-pointer" matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>New Password</mat-label>
            <input name="newPassword" autocomplete="new-password" formControlName="newPassword" matInput [type]="hidePassword ? 'password' : 'text'" />
          </mat-form-field>
          <mat-form-field class="w-full" subscriptSizing="dynamic">
            <mat-label>Confirm Password</mat-label>
            <input name="confirmPassword" autocomplete="new-password" formControlName="confirmPassword" matInput [type]="hidePassword ? 'password' : 'text'" />
          </mat-form-field>
          <div class="h-12">
            <mat-error>
              <span *ngIf="changePasswordGroup.hasError('passwordRequired')">Password required <br /></span>
              <span *ngIf="changePasswordGroup.hasError('passwordsMatch')">Passwords do not match <br /></span>
              <span *ngFor="let message of errorMessages">{{ message.charAt(0).toUpperCase() + message.slice(1) }}<br /></span>
            </mat-error>
          </div>
          <div class="w-full flex justify-center mt-4">
            <div class="w-1/2">
              <button
                class="w-full"
                mat-flat-button
                color="primary"
                [disabled]="changePasswordGroup.invalid"
                (click)="resetPassword()"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
        <div class="ml-6">
          <span class="font-medium">Password Requirements:</span><br />
          <span [ngClass]="{complete: changePasswordGroup.controls['newPassword'].value, error: changePasswordGroup.hasError('minLength')}">8 Characters Minimum Length</span><br />
          <span [ngClass]="{complete: changePasswordGroup.controls['newPassword'].value, error: changePasswordGroup.hasError('oneNumber')}">At least 1 number</span><br />
          <span [ngClass]="{complete: changePasswordGroup.controls['newPassword'].value, error: changePasswordGroup.hasError('oneSpecial')}">At least 1 special character</span><br />
          <span [ngClass]="{complete: changePasswordGroup.controls['newPassword'].value, error: changePasswordGroup.hasError('oneUpper')}">At least 1 uppercase letter</span><br />
          <span [ngClass]="{complete: changePasswordGroup.controls['newPassword'].value, error: changePasswordGroup.hasError('oneLower')}">At least 1 lowercase letter</span>
        </div>
      </div>
    </div>
  </div>
</div>
