import { Component } from '@angular/core';
import {Environment} from "../../../core/models/environment.interface";
import {EnvironmentService} from "../../../core/services/environment.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss']
})
export class EnvironmentComponent {
  loading: boolean = true;
  // environment: Environment;
  environment: Record<any,any>;
  constructor(public environmentService: EnvironmentService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.environmentService.findOne( params['environmentId']).subscribe(response => {
        this.environment = {...response};
        this.loading = false;
      })
    });
  }
}
