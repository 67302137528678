<section class="p-2">
	<div class="card">
		<div class="flex justify-between">
			<h2>Monitor Log</h2>
		</div>
    <form class="flex justify-around items-center p-2" [formGroup]="filterForm">
      <mat-form-field class="w-1/4" color="primary" subscriptSizing="dynamic">
        <mat-label>Search</mat-label>
        <input type="text" placeholder="Search..." matInput formControlName="searchTerm" />
      </mat-form-field>
      <mat-form-field class="w-1/4" subscriptSizing="dynamic">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" formControlName="startDate">
          <input matEndDate placeholder="End date" formControlName="endDate">
        </mat-date-range-input>
        <button *ngIf="filterForm?.get('startDate')?.value || filterForm?.get('endDate')?.value" mat-icon-button matIconSuffix (click)="resetDatePicker()"><mat-icon class="text-gray-600">close</mat-icon></button>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option value="SUCCESS">
            Success
          </mat-option>
          <mat-option value="FAILURE">
            Failure
          </mat-option>
          <mat-option value="">
            All
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Order</mat-label>
        <mat-select formControlName="orderBy">
          <mat-option value="ASC">
            Ascending
          </mat-option>
          <mat-option value="DESC">
            Descending
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="searchForLogs()" type="submit">Search</button>
    </form>

		<mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>

		<div class="max-h-[calc(100vh-320px)] overflow-auto">
			<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" *ngIf="!loading">
				<!--CreatedTs Column-->
				<ng-container matColumnDef="createdTs">
					<th mat-header-cell *matHeaderCellDef>Time Logged</th>
					<td mat-cell *matCellDef="let log">
						{{ log.createdTs | date : 'short' }}
					</td>
				</ng-container>
        <!--Status Column-->
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef>Status</th>
					<td mat-cell *matCellDef="let log">
						{{ log.status }}
					</td>
				</ng-container>
				<!--Organization Column-->
				<ng-container matColumnDef="orgName">
					<th mat-header-cell *matHeaderCellDef>Organization</th>
					<td mat-cell *matCellDef="let log">
						{{ log.orgName }}
					</td>
				</ng-container>
				<!--Location Column-->
				<ng-container matColumnDef="locName">
					<th mat-header-cell *matHeaderCellDef>Location</th>
					<td mat-cell *matCellDef="let log">
						{{ log.locName }}
					</td>
				</ng-container>
        <!--Payload Column-->
				<ng-container matColumnDef="payload">
					<th mat-header-cell *matHeaderCellDef>Payload</th>
					<td mat-cell *matCellDef="let log">
						<button *ngIf="log.apiPayload" mat-icon-button (click)="openPayloadComponent($event, log)"><mat-icon>data_object</mat-icon></button>
					</td>
				</ng-container>
        <!--Alias Column-->
        <ng-container matColumnDef="alias">
          <th mat-header-cell *matHeaderCellDef>Alias</th>
          <td mat-cell *matCellDef="let log">
           {{log.alias}}
          </td>
        </ng-container>
				<!--Client Column-->
				<ng-container matColumnDef="client">
					<th mat-header-cell *matHeaderCellDef>Client</th>
					<td mat-cell *matCellDef="let log">
						{{ log.client }}
					</td>
				</ng-container>
				<!--Service Column-->
				<ng-container matColumnDef="service">
					<th mat-header-cell *matHeaderCellDef>Service</th>
					<td mat-cell *matCellDef="let log">
						{{ log.service }}
					</td>
				</ng-container>
				<!--Sow Column-->
				<ng-container matColumnDef="sow">
					<th mat-header-cell *matHeaderCellDef>SOW</th>
					<td mat-cell *matCellDef="let log">
						{{ log.sow }}
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
				<tr mat-row *matRowDef="let log; let i = dataIndex; columns: columnsToDisplay" class="cursor-pointer" (click)="openMonitorLogModal(log)"></tr>
				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row flex" *matNoDataRow>
					<td class="mat-cell p-4" colspan="4" *ngIf="!loading">No results match the selected filters...</td>
				</tr>
			</table>
		</div>
		<mat-paginator [length]="monitorLogResponse.total" [pageSizeOptions]="[10, 20, 50]" pageSize="50" showFirstLastButtons></mat-paginator>
	</div>
</section>
