import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '../../../core/models/user.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {UserService} from '../../../core/services/user.service';

@Component({
	selector: 'app-user-card',
	templateUrl: './user-card.component.html',
	styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent {
	@Input() readOnly: boolean = false;
	@Input() user: User;
	@Input() isChecked: boolean = false;
	@Output() checked = new EventEmitter<User>();
	@Output() unchecked = new EventEmitter<number>();
	constructor(private userService: UserService) {}

	getInitials(name: string): string {
		return name
			.split(' ')
			.map((n) => n[0])
			.join('');
	}

	getColor(name: string): string {
		return this.userService.getUserColor(name);
	}

	fullName(): string {
		return this.user.firstName.concat(' ', this.user.lastName);
	}

	userSelected(event: MatCheckboxChange) {
		if (event.checked) {
			this.checked.emit(this.user);
		} else {
			this.unchecked.emit(this.user.id);
		}
	}
}
