import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrganizationService} from '../../../core/services/organization.service';
import {Organization} from '../../../core/models/organization.interface';
import {Service} from '../../../core/models/service.interface';
import {Location} from '../../../core/models/location.interface';
import {LocationService} from '../../../core/services/location.service';
import {ClientService} from '../../../core/services/client.service';
import {Client} from '../../../core/models/client.interface';
import {Interface} from '../../../core/models/interface.interface';
import {Environment} from '../../../core/models/environment.interface';
import {InterfaceService} from '../../../core/services/interface.service';
import {ServiceService} from '../../../core/services/service.service';
import {EnvironmentService} from '../../../core/services/environment.service';
import {Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {Configuration} from '../../../core/models/configuration.interface';
import {StatusaiEnum} from '../../../core/enums/statusai-enum';
import {LogLevelEnum} from '../../../core/enums/log-level.enum';
import {ConfigurationService} from '../../../core/services/configuration.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {AliasConfig} from '../../../core/models/alias-config.interface';
import {MatSnackBar} from '@angular/material/snack-bar';

type ConfigRef = Interface | Client | Service | Environment;

@Component({
  selector: 'app-create-configuration',
  templateUrl: './create-configuration.component.html',
  styleUrls: ['./create-configuration.component.scss']
})
export class CreateConfigurationComponent implements OnInit, OnDestroy{
  form: FormGroup;
  organizations: Organization[];
  locations: Location[];
  interfaces: Interface[];
  clients: Client[];
  services: Service[];
  environments: Environment[];
  logLevels: LogLevelEnum[] = Object.values(LogLevelEnum);
  errorMessage: string;

  organizationSubscription: Subscription;
  locationSubscription: Subscription;
  interfaceSubscription: Subscription;
  clientSubscription: Subscription;
  serviceSubscription: Subscription;
  environmentSubscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateConfigurationComponent>,
    private configurationService: ConfigurationService,
    private organizationService: OrganizationService,
    private locationService: LocationService,
    private interfaceService: InterfaceService,
    private clientService: ClientService,
    private serviceService: ServiceService,
    private environmentService: EnvironmentService,
    private snackbar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      id: [null],
      configDescription: ['', Validators.required],
      aliasName: [null],
      orgId: [null, Validators.required],
      locId: [null, Validators.required],
      clientId: [null, Validators.required],
      serviceId: [null, Validators.required],
      interfaceId: [null, Validators.required],
      environmentId: [null, Validators.required],
      client: [null],
      service: [null],
      interface: [null],
      environment: [null],
      statusai: [StatusaiEnum.ACTIVE],
      loglevel: [LogLevelEnum.FULL],
      notifyApiUrl: [null],
      notifyEmailToList: [''],
      notifyEmailCcList: [''],
      notifyEmailBccList: [''],
      notifyTextList: [''],
      notifyMsgSubject: [null],
      notifyMsgBody: [null],
      alias: this.formBuilder.group({
        id: [null],
        alias: [null, Validators.required],
        aliasName: [null],
        aliasDescription: [null],
        configId: [null],
      })
    });
  }

  ngOnInit() {
    this.organizationSubscription = this.organizationService.findAll()
      .subscribe((organizations: Organization[]) => {
      this.organizations = organizations;
    })
  }

  ngOnDestroy() {
    this.organizationSubscription?.unsubscribe();
    this.locationSubscription?.unsubscribe();
    this.interfaceSubscription?.unsubscribe();
    this.clientSubscription?.unsubscribe();
    this.serviceSubscription?.unsubscribe();
    this.environmentSubscription?.unsubscribe();
  }

  get active(): boolean {
    return this.form.get('statusai')?.value === StatusaiEnum.ACTIVE;
  }

  toggleActive(): void {
    this.form.get('statusai')?.setValue(this.active ? StatusaiEnum.INACTIVE : StatusaiEnum.ACTIVE);
  }

  onOrgSelect(): void {
    this.resetLocationDependantDropdowns();
    this.form.get('locId')?.setValue(null);
    this.form.get('interfaceId')?.setValue(null);

    const orgId: number = this.form.get('orgId')?.value;
    this.locationSubscription = this.locationService.findAll(orgId)
      .subscribe((locations: Location[]) => {
      this.locations = locations;
      if (this.locations.length === 1) {
        this.form.get('locId')?.setValue(this.locations[0].id);
        this.onLocationSelect();
      }
    });
    this.interfaceSubscription = this.interfaceService.findAll(orgId)
      .subscribe((interfaces: Interface[]) => {
      this.interfaces = interfaces;
      if (this.interfaces.length === 1) {
        this.form.get('interfaceId')?.setValue(this.interfaces[0].id);
        this.setReferenceName('interfaceId', 'interface', interfaces);
      }
    });
  }

  onLocationSelect(): void {
    this.resetLocationDependantDropdowns();
    const locId: number = this.form.get('locId')?.value;
    this.clientSubscription = this.clientService.findAll(locId)
      .subscribe((clients: Client[]) => {
      this.clients = clients;
      if (this.clients.length === 1) {
        this.form.get('clientId')?.setValue(this.clients[0].id);
        this.setReferenceName('clientId', 'client', clients);
      }
    });
    this.serviceSubscription = this.serviceService.findAll(locId)
      .subscribe((services: Service[]) => {
      this.services = services;
      if (this.services.length === 1) {
        this.form.get('serviceId')?.setValue(this.services[0].id);
        this.setReferenceName('serviceId', 'service', services);
      }
    });
    this.environmentSubscription = this.environmentService.findAll(locId)
      .subscribe((environments: Environment[]) => {
      this.environments = environments;
      if (this.environments.length === 1) {
        this.form.get('environmentId')?.setValue(this.environments[0].id);
        this.setReferenceName('environmentId', 'environment', environments);
      }
    });
  }

  resetLocationDependantDropdowns(): void {
    this.form.get('clientId')?.setValue(null);
    this.form.get('serviceId')?.setValue(null);
    this.form.get('environmentId')?.setValue(null);
  }

  setReferenceName(formId: string, formName: string, referenceArray: any[]): void {
    const id: number = this.form.get(formId)?.value;
    this.form.get(formName)?.setValue(referenceArray.find((ref: ConfigRef) => ref.id === id)[formName] ?? '')
  }

  close(): void {
    this.dialogRef.close();
  }

  createConfig(): void {
    const formValue: Configuration = this.form.getRawValue();
    formValue.aliasName = formValue.alias.alias;

    this.configurationService.createAliasConfig(formValue).subscribe(
      (response: AliasConfig) => {
        this.dialogRef.close(response);
        this.snackbar.open('Configuration Successfully Created!', SnackbarActionEnum.SUCCESS);
      },
      (error) => {
        this.errorMessage = error?.error?.errorDetails?.driverError?.detail;
        this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
      }
    );
  }
}
