import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Environment} from "../../../core/models/environment.interface";
import {MatDialog} from "@angular/material/dialog";
import {EnvironmentCreateComponent} from "../environment-create/environment-create.component";
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EnvironmentService} from '../../../core/services/environment.service';

@Component({
  selector: 'app-environment-list',
  templateUrl: './environment-list.component.html',
  styleUrls: ['./environment-list.component.scss']
})
export class EnvironmentListComponent implements OnChanges {
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  sort: MatSort;
  dataSource: MatTableDataSource<Environment> = new MatTableDataSource<Environment>();
  columnsToDisplay: string[] = ['name', 'description', 'actions'];
  loading: boolean = false;
  @Input() parentOrgId: number;
  @Input() parentLocId: number;
  @Input() environments: Environment[] = [];

  constructor(public dialog: MatDialog, private snackbar: MatSnackBar, private environmentService: EnvironmentService) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['environments']){
      if(this.environments) this.dataSource = new MatTableDataSource<Environment>(this.environments);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(EnvironmentCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.parentOrgId = this.parentOrgId;
    dialogRef.componentInstance.parentLocId = this.parentLocId;
    dialogRef.afterClosed().subscribe((createdEnvironment: Environment) => {
      if (createdEnvironment){
        //update environments array with new value
        this.environments?.push(createdEnvironment);
        this.dataSource = new MatTableDataSource<Environment>(this.environments);
      }
    })
  }

  openUpdateModal(event: Event, environment: Environment){
    event.stopPropagation();
    const dialogRef = this.dialog.open(EnvironmentCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.createMode = false;
    dialogRef.componentInstance.environment = environment;
    dialogRef.afterClosed().subscribe((updatedEnvironment: Environment) => {
      if (updatedEnvironment) {
        //update environment array with new value
        const index = this.environments.findIndex((loc: Environment) => loc.id === updatedEnvironment.id);
        this.environments[index] = updatedEnvironment;
        this.dataSource = new MatTableDataSource<Environment>(this.environments);
      }
    })
  }

  deleteEnvironment(env: Environment): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Configuration',
        message: `Are you sure you want to delete ${env.environmentName}?`
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.environmentService.remove(env.id).subscribe(() => {
            const index: number = this.environments.findIndex((environment : Environment) => environment.id === env.id);
            if (index > -1) {
              this.environments.splice(index, 1);
              this.dataSource = new MatTableDataSource<Environment>(this.environments);
              this.snackbar.open(`Successfully deleted ${env.environmentName}.`, SnackbarActionEnum.SUCCESS);
            }
          }, error => {
            this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
          })
        }
      });
  }
}
