<section class="card scroll-table-card">
    <div class="flex justify-between">
      <h2>Interfaces</h2>
      <button mat-mini-fab color="primary" (click)="openCreateModal()"><mat-icon>add</mat-icon></button>
    </div>
    <mat-form-field class="w-1/2 mb-2" color="primary" subscriptSizing="dynamic">
      <mat-label>Search</mat-label>
      <input
        id="user-searchbar"
        type="text"
        (keyup)="applyFilter($event)"
        placeholder="Search..."
        matInput
        #searchInput
      />
    </mat-form-field>

    <mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>

  <div class="max-h-[25vh] overflow-auto">
    <table mat-table [dataSource]="dataSource" class="w-full" matSort *ngIf="!loading">
      <!--Name Column-->
      <ng-container matColumnDef="interface">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by interface">Interface</th>
        <td mat-cell *matCellDef="let int">
          {{ int.interface }}
        </td>
      </ng-container>
      <!--Name Column-->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
        <td mat-cell *matCellDef="let int">
          {{ int.interfaceName }}
        </td>
      </ng-container>
      <!--Type Column-->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">Type</th>
        <td mat-cell *matCellDef="let int">
          {{ int.interfaceType }}
        </td>
      </ng-container>
      <!--Actions Column-->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let int">
          <button mat-icon-button (click)="openUpdateModal($event, int)"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button (click)="$event.stopPropagation(); deleteInterface(int)"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let int; let i = dataIndex; columns: columnsToDisplay" class="cursor-pointer" [routerLink]="'interface/' + int.id"></tr>
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row flex" *matNoDataRow>
        <td class="mat-cell p-4" colspan="4" *ngIf="!loading">No data matching the filter "{{ searchInput.value }}"</td>
      </tr>
    </table>
  </div>
</section>
