<section class="p-2">
	<div class="card">
		<div class="flex justify-between">
			<h2>Organizations</h2>
			<button mat-mini-fab color="primary" (click)="openCreateModal()"><mat-icon>add</mat-icon></button>
		</div>
		<mat-form-field class="w-1/2 mb-2" color="primary" subscriptSizing="dynamic">
			<mat-label>Search</mat-label>
			<input
				id="user-searchbar"
				type="text"
				(keyup)="applyFilter($event)"
				placeholder="Search..."
				matInput
				#searchInput
			/>
		</mat-form-field>

    <mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>

    <div class="max-h-[70vh] overflow-auto">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!loading">
        <!--Name Column-->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Name</th>
          <td mat-cell *matCellDef="let org">
            {{ org.orgName }}
          </td>
        </ng-container>
        <!--Contact Column-->
        <ng-container matColumnDef="contact">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Contact</th>
          <td mat-cell *matCellDef="let org">
            {{ org.contactName }}
          </td>
        </ng-container>
        <!--Actions Column-->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let org">
            <button mat-icon-button (click)="openUpdateModal($event, org)"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button (click)="$event.stopPropagation(); deleteOrganization(org)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let org; let i = dataIndex; columns: columnsToDisplay" class="cursor-pointer" [routerLink]="'/organization/' + org.id"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row flex" *matNoDataRow>
          <td class="mat-cell p-4" colspan="4" *ngIf="!loading">No data matching the filter "{{ searchInput.value }}"</td>
        </tr>
      </table>
    </div>
	</div>
</section>
