import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Client} from "../../../core/models/client.interface";
import {MatDialog} from "@angular/material/dialog";
import {ClientCreateComponent} from "../client-create/client-create.component";
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {ClientService} from '../../../core/services/client.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnChanges {
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  sort: MatSort;
  dataSource: MatTableDataSource<Client> = new MatTableDataSource<Client>();
  columnsToDisplay: string[] = ['name', 'description', 'actions'];
  loading: boolean = false;
  @Input() parentOrgId: number;
  @Input() parentLocId: number;
  @Input() clients: Client[]= [];
  constructor(public dialog: MatDialog, private snackbar: MatSnackBar, private clientService: ClientService) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['clients']){
      if(this.clients) this.dataSource = new MatTableDataSource<Client>(this.clients);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(ClientCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.parentOrgId = this.parentOrgId;
    dialogRef.componentInstance.parentLocId = this.parentLocId;
    dialogRef.afterClosed().subscribe((createdClient: Client) => {
      if (createdClient){
        //update clients array with new value
        this.clients?.push(createdClient);
        this.dataSource = new MatTableDataSource<Client>(this.clients);
      }
    })
  }

  openUpdateModal(event: Event, client: Client){
    event.stopPropagation();
    const dialogRef = this.dialog.open(ClientCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.createMode = false;
    dialogRef.componentInstance.client = client;
    dialogRef.afterClosed().subscribe((updatedClient: Client) => {
      if (updatedClient) {
        //update client array with new value
        const index = this.clients.findIndex((loc: Client) => loc.id === updatedClient.id);
        this.clients[index] = updatedClient;
        this.dataSource = new MatTableDataSource<Client>(this.clients);
      }
    })
  }

  deleteClient(cli: Client): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Configuration',
        message: `Are you sure you want to delete ${cli.clientName}?`
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.clientService.remove(cli.id).subscribe(() => {
            const index: number = this.clients.findIndex((client : Client) => client.id === cli.id);
            if (index > -1) {
              this.clients.splice(index, 1);
              this.dataSource = new MatTableDataSource<Client>(this.clients);
              this.snackbar.open(`Successfully deleted ${cli.clientName}.`, SnackbarActionEnum.SUCCESS);
            }
          }, error => {
            this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
          })
        }
      });
  }
}
