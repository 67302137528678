<div class="m-auto flex flex-row justify-center w-full">
	<div class="reset">
		<h1 class="pt-8 pl-8">Reset Password</h1>
		<span class="pl-8">A one-time password reset is required when logging in for the first time.</span>
		<div class="flex flex-col p-8 h-[300px]" [formGroup]="resetForm">
			<div class="flex-row flex w-full h-full">
				<div>
					<mat-form-field class="w-full">
						<mat-label>Password</mat-label>
						<input name="password" formControlName="password" matInput [type]="hide ? 'password' : 'text'" />
						<mat-icon class="cursor-pointer" matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
					</mat-form-field>
					<mat-form-field class="w-full" subscriptSizing="dynamic">
						<mat-label>Confirm Password</mat-label>
						<input name="confirmPassword" formControlName="confirmPassword" matInput [type]="hide ? 'password' : 'text'" />
					</mat-form-field>
					<div class="h-12">
						<mat-error
							*ngIf="(resetForm.hasError('passwordsMatch') && resetForm.controls['confirmPassword'].dirty) || errorMessages.length"
						>
							<span *ngIf="resetForm.hasError('passwordsMatch')">Passwords do not match <br /></span>
							<span *ngFor="let message of errorMessages">{{ message.charAt(0).toUpperCase() + message.slice(1) }}<br /></span>
						</mat-error>
					</div>
					<div class="w-full flex justify-center mt-4">
						<div class="w-1/2">
							<button
								class="w-full"
								mat-flat-button
								color="primary"
								[disabled]="resetForm.hasError('passwordsMatch')"
								(click)="resetPassword()"
							>
								Reset Password
							</button>
						</div>
					</div>
				</div>
				<div class="ml-6">
					<span class="font-medium">Password Requirements:</span><br />
					<span>8 Characters Minimum Length</span><br />
					<span>At least 1 number</span><br />
					<span>At least 1 special character</span><br />
					<span>At least 1 uppercase letter</span><br />
					<span>At least 1 lowercase letter</span>
				</div>
			</div>
		</div>
	</div>
</div>
