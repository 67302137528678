<h2 mat-dialog-title>Create User</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
	<mat-form-field class="w-1/4 pt-2 pr-2">
		<mat-label>First Name</mat-label>
		<input matInput formControlName="firstName" />
		<mat-error *ngIf="form.get('firstName')?.hasError('required')"> First name is required </mat-error>
		<mat-error *ngIf="form.get('firstName')?.hasError('maxlength')">
			{{ this.form.get('firstName')?.value.length }} / 100 Maximum Characters Allowed</mat-error
		>
	</mat-form-field>
	<mat-form-field class="w-1/4 pt-2 pr-2">
		<mat-label>Last Name</mat-label>
		<input matInput formControlName="lastName" />
		<mat-error *ngIf="form.get('lastName')?.hasError('required')"> Last name is required </mat-error>
		<mat-error *ngIf="form.get('lastName')?.hasError('maxlength')">
			{{ this.form.get('lastName')?.value.length }} / 100 Maximum Characters Allowed</mat-error
		>
	</mat-form-field>
	<mat-form-field class="w-1/2 pt-2">
		<mat-label>Email</mat-label>
		<input matInput formControlName="email" />
		<mat-error *ngIf="form.get('email')?.hasError('required')"> Email is required </mat-error>
		<mat-error *ngIf="form.get('email')?.hasError('maxlength')">
			{{ this.form.get('email')?.value.length }} / 254 Maximum Characters Allowed</mat-error
		>
		<mat-error *ngIf="form.get('email')?.hasError('pattern')"> Please enter a valid email address </mat-error>
	</mat-form-field>
	<mat-form-field class="w-1/4 pr-2" (click)="form.enable()" tabindex="0">
		<mat-label>Role</mat-label>
		<mat-select formControlName="roleId">
			<mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
		</mat-select>
	</mat-form-field>
  <mat-form-field class="w-1/4 pr-2" (click)="form.enable()" tabindex="0">
    <mat-label>Organization</mat-label>
    <mat-select formControlName="orgId">
      <mat-option value="0"></mat-option>
      <mat-option *ngFor="let organization of organizations" [value]="organization.id">{{ organization.orgName }}</mat-option>
    </mat-select>
  </mat-form-field>
	<div class="h-20">
		<mat-form-field class="pr-2">
			<mat-label>Phone No.</mat-label>
			<input matInput formControlName="phone" />
			<mat-error *ngIf="form.get('phone')?.hasError('maxlength')">
				{{ this.form.get('phone')?.value.length }} / 40 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
		<mat-form-field class="w-3/12">
			<mat-label>Ext</mat-label>
			<input matInput formControlName="phoneExt" />
			<mat-error *ngIf="form.get('phoneExt')?.hasError('maxlength')">
				{{ this.form.get('phoneExt')?.value.length }} / 10 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
	</div>
	<fieldset class="w-1/2 border border-gray-500 rounded">
		<legend class="ml-4 px-2">User Type*</legend>
		<mat-radio-group aria-label="Select an option" color="primary" formControlName="type">
			<mat-radio-button class="w-1/3 pl-2" [value]="option" *ngFor="let option of userTypeOptions">{{
				option | titlecase
			}}</mat-radio-button>
		</mat-radio-group>
	</fieldset>
</mat-dialog-content>
<div class="flex justify-end p-4">
	<button mat-button mat-dialog-close class="mr-2">Cancel</button>
	<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createUser()">Create</button>
</div>
