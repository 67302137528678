import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Service} from "../models/service.interface";
import {Observable} from "rxjs";
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  baseUrl: string = `${environment.url}/service`;
  constructor(private http: HttpClient) {}

  create(services: Service): Observable<Service> {
    return this.http.post<Service>(this.baseUrl, services);
  }

  findAll(locId?: number): Observable<Service[]> {
    let params: HttpParams = new HttpParams();

    if (locId) {
      params = params.append('locId', locId);
    }

    return this.http.get<Service[]>(this.baseUrl, {params});
  }

  findOne(id: number): Observable<Service> {
    return this.http.get<Service>(`${this.baseUrl}/${id}`);
  }

  update(services: Service): Observable<Service> {
    return this.http.put<Service>(`${this.baseUrl}/${services.id}`, services);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
