import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-delimited-list',
  templateUrl: './delimited-list.component.html',
  styleUrls: ['./delimited-list.component.scss']
})
export class DelimitedListComponent {
  @Input() delimitedString: string = '';
  @Input() delimiter: string = ',';
  @Input() delimiterName: string = 'comma';
  @Input() label: string;
  @Output() updatedDelimitedString: EventEmitter<string> = new EventEmitter<string>();

  form: FormGroup;
  showAddField: boolean = false;

  constructor(private formBuilder: FormBuilder, private ref: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      addInput: ['']
    });
  }

  get splitList(): string[] {
    return this.delimitedString ? this.delimitedString.split(this.delimiter) : [];
  }

  showAdd(): void {
    this.showAddField = true;
    this.ref.detectChanges();
  }

  hideAdd(): void {
    this.showAddField = false;
    this.form.get('addInput')?.setValue('');
  }

  addItem(): void {
    const itemString: string = this.form.get('addInput')?.value;
    const itemsToAdd: string[] = itemString.split(this.delimiter)
      .map((item: string) => item.trim());

    const newList: string[] = this.splitList.concat(itemsToAdd);
    this.updatedDelimitedString.emit(newList.join(this.delimiter));
    this.hideAdd();
  }

  removeItem(item: string): void {
    const newList: string[] = this.splitList;
    const index: number = newList.indexOf(item);

    if (index > -1) {
      newList.splice(index, 1);
      this.updatedDelimitedString.emit(newList.join(this.delimiter));
    }
  }

}
