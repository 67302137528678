import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitorLogListComponent } from './monitor-log-list/monitor-log-list.component';
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import { MonitorLogComponent } from './monitor-log/monitor-log.component';
import {MatDialogModule} from "@angular/material/dialog";
import {ClipboardModule} from "ngx-clipboard";

@NgModule({
  declarations: [
    MonitorLogListComponent,
    MonitorLogComponent
  ],
  imports: [
    MatTableModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    RouterLink,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatSelectModule,
    MatDialogModule,
    ClipboardModule
  ]
})
export class MonitorLogModule { }
