import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ServiceService} from "../../../core/services/service.service";
import {MatDialogRef} from "@angular/material/dialog";
import {SnackbarActionEnum} from "../../../core/enums/snackbar-action.enum";
import {Service} from "../../../core/models/service.interface";

@Component({
  selector: 'app-service-create',
  templateUrl: './service-create.component.html',
  styleUrls: ['./service-create.component.scss']
})
export class ServiceCreateComponent {
  form: FormGroup;
  createMode: boolean = true;
  service: Service;
  parentOrgId: number = 0;
  parentLocId: number = 0;

  constructor(private formBuilder: FormBuilder, private snackbar: MatSnackBar, private serviceService: ServiceService, public dialogRef: MatDialogRef<ServiceCreateComponent>) {
    this.form = this.formBuilder.group({
      id: [0],
      orgId:[this.parentOrgId],
      locId:[this.parentLocId],
      service: ['', [Validators.required, Validators.maxLength(255)]],
      serviceName: ['', [Validators.required, Validators.maxLength(255)]],
      serviceDescription: ['', [Validators.maxLength(255)]],
      serviceTerm: ['', [Validators.required, Validators.maxLength(50)]],
      sow: ['', [Validators.required, Validators.maxLength(50)]],
      project: ['', [Validators.required, Validators.maxLength(50)]],
      signedDate: ['', [Validators.required, Validators.maxLength(50)]],
      signedByName: ['', [Validators.required, Validators.maxLength(50)]],
      signedByTitle: ['', [Validators.required, Validators.maxLength(50)]],
      monthlyFee: ['', [Validators.required, Validators.maxLength(10)]]
    });
  }

  ngOnInit() {
    if(this.createMode){
      this.form.controls['orgId'].setValue(this.parentOrgId);
      this.form.controls['locId'].setValue(this.parentLocId);
    } else {
      this.form.patchValue(this.service)
    }
  }

  createOrUpdateService() {
    if (this.createMode){
      this.serviceService.create(this.form.getRawValue()).subscribe(
        (response: Service) => {
          this.dialogRef.close(response);
          this.snackbar.open('Service Successfully Created!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      );
    } else {
      this.serviceService.update(this.form.getRawValue()).subscribe(
        (response: Service) => {
          this.dialogRef.close(response);
          this.snackbar.open('Service Updated Successfully!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      )
    }
  }
}
