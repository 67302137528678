import { Component, OnInit } from '@angular/core';
import {Breadcrumb} from "../../models/breadcrumb.interface";
import {BreadcrumbService} from "../../services/breadcrumb.service";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbSubject.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs;
    })
  }
}
