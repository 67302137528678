import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Endpoint} from "../../../../../core/models/endpoint.interface";
import {MatDialog} from "@angular/material/dialog";
import {EndpointCreateComponent} from "../endpoint-create/endpoint-create.component";
import {
  ConfirmationDialogComponent
} from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../../../../../core/enums/snackbar-action.enum';
import {EndpointService} from '../../../../../core/services/endpoint.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-endpoint-list',
  templateUrl: './endpoint-list.component.html',
  styleUrls: ['./endpoint-list.component.scss']
})
export class EndpointListComponent implements OnChanges {
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  sort: MatSort;
  dataSource: MatTableDataSource<Endpoint> = new MatTableDataSource<Endpoint>();
  columnsToDisplay: string[] = ['name', 'description', 'actions'];
  loading: boolean = false;
  @Input() parentOrgId: number;
  @Input() parentServiceId: number;
  @Input() parentInterfaceId: number;
  @Input() endpoints: Endpoint[] = [];
  constructor(public dialog: MatDialog, private endpointService: EndpointService, private snackbar: MatSnackBar) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['endpoints']){
      if(this.endpoints) this.dataSource = new MatTableDataSource<Endpoint>(this.endpoints);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(EndpointCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.parentOrgId = this.parentOrgId;
    dialogRef.componentInstance.parentServiceId = this.parentServiceId;
    dialogRef.componentInstance.parentInterfaceId = this.parentInterfaceId;
    dialogRef.afterClosed().subscribe((createdLoc: Endpoint) => {
      if (createdLoc){
        //update endpoints array with new value
        this.endpoints?.push(createdLoc);
        this.dataSource = new MatTableDataSource<Endpoint>(this.endpoints);
      }
    })
  }

  openUpdateModal(event: Event, endpoint: Endpoint){
    event.stopPropagation();
    const dialogRef = this.dialog.open(EndpointCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.createMode = false;
    dialogRef.componentInstance.endpoint = endpoint;
    dialogRef.afterClosed().subscribe((updatedLoc: Endpoint) => {
      if (updatedLoc) {
        //update endpoint array with new value
        const index = this.endpoints.findIndex((loc: Endpoint) => loc.id === updatedLoc.id);
        this.endpoints[index] = updatedLoc;
        this.dataSource = new MatTableDataSource<Endpoint>(this.endpoints);
      }
    })
  }

  deleteEndpoint(end: Endpoint): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Configuration',
        message: `Are you sure you want to delete ${end.endpointName}?`
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.endpointService.remove(end.id).subscribe(() => {
            const index: number = this.endpoints.findIndex((endpoint : Endpoint) => endpoint.id === end.id);
            if (index > -1) {
              this.endpoints.splice(index, 1);
              this.dataSource = new MatTableDataSource<Endpoint>(this.endpoints);
              this.snackbar.open(`Successfully deleted ${end.endpointName}.`, SnackbarActionEnum.SUCCESS);
            }
          }, error => {
            this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
          })
        }
      });
  }
}
