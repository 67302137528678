import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, from, Observable, switchMap, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {LoginComponent} from '../components/login/login.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private dialog: MatDialog, private authService: AuthService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const cloned: HttpRequest<any> = request.clone({withCredentials: true});

		return next.handle(cloned).pipe(
			catchError((error: any) => {
				if (error.status !== 401) {
					return throwError(error);
				}
				if (this.authService.userLoggedIn) {
					this.authService.userLoggedIn = false;
					return from(
						this.dialog
							.open(LoginComponent, {
								width: '400px',
								disableClose: true
							})
							.afterClosed()
					).pipe(switchMap(() => next.handle(cloned)));
				} else {
					return throwError(error);
				}
			})
		);
	}
}
export const AuthInterceptorProviders = [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}];
