import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Interface} from "../../../../../core/models/interface.interface";
import {MatDialog} from "@angular/material/dialog";
import {InterfaceCreateComponent} from "../interface-create/interface-create.component";
import {
  ConfirmationDialogComponent
} from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../../../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InterfaceService} from '../../../../../core/services/interface.service';

@Component({
  selector: 'app-interface-list',
  templateUrl: './interface-list.component.html',
  styleUrls: ['./interface-list.component.scss']
})
export class InterfaceListComponent implements OnChanges {
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  sort: MatSort;
  dataSource: MatTableDataSource<Interface> = new MatTableDataSource<Interface>();
  columnsToDisplay: string[] = [ 'name', 'interface', 'type', 'actions'];
  loading: boolean = false;
  @Input() parentOrgId: number;
  @Input() parentServiceId: number;
  @Input() interfaces: Interface[] = [];
  constructor(public dialog: MatDialog, private snackbar: MatSnackBar, private interfaceService: InterfaceService) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['interfaces']){
      if(this.interfaces) this.dataSource = new MatTableDataSource<Interface>(this.interfaces);
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(InterfaceCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.parentOrgId = this.parentOrgId;
    dialogRef.componentInstance.parentServiceId = this.parentServiceId;
    dialogRef.afterClosed().subscribe((createdInterface: Interface) => {
      if (createdInterface){
        //update interfaces array with new value
        this.interfaces?.push(createdInterface);
        this.dataSource = new MatTableDataSource<Interface>(this.interfaces);
      }
    })
  }

  openUpdateModal(event: Event, interfaceObj: Interface){
    event.stopPropagation();
    const dialogRef = this.dialog.open(InterfaceCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.createMode = false;
    dialogRef.componentInstance.interfaceObj = interfaceObj;
    dialogRef.afterClosed().subscribe((updatedInt: Interface) => {
      if (updatedInt) {
        //update interface array with new value
        const index = this.interfaces.findIndex((int: Interface) => int.id === updatedInt.id);
        this.interfaces[index] = updatedInt;
        this.dataSource = new MatTableDataSource<Interface>(this.interfaces);
      }
    })
  }

  deleteInterface(inter: Interface): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Configuration',
        message: `Are you sure you want to delete ${inter.interfaceName}?`
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.interfaceService.remove(inter.id).subscribe(() => {
            const index: number = this.interfaces.findIndex((_interface : Interface) => _interface.id === inter.id);
            if (index > -1) {
              this.interfaces.splice(index, 1);
              this.dataSource = new MatTableDataSource<Interface>(this.interfaces);
              this.snackbar.open(`Successfully deleted ${inter.interfaceName}.`, SnackbarActionEnum.SUCCESS);
            }
          }, error => {
            this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
          })
        }
      });
  }
}
