<div class="p-2">
  <mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>
  <mat-grid-list *ngIf="!loading" cols="3" rowHeight="40vh" gutterSize=".5rem">
    <mat-grid-tile colspan="3" rowspan="1">
      <div class="card">
        <h2>Interface</h2>
        <!--Interface info-->
        <div class="flex flex-wrap">
          <div *ngFor="let item of interface | keyvalue;" class="w-1/3">
            <p><strong>{{ item.key }}:</strong> {{ item.value }}</p>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="3" rowspan="1">
      <app-endpoint-list  class="" [endpoints]="interfaceObj.endpoints" [parentOrgId]="interfaceObj.orgId" [parentInterfaceId]="interfaceObj.id" [parentServiceId]="interfaceObj.serviceId"></app-endpoint-list>
    </mat-grid-tile>
  </mat-grid-list>
</div>

