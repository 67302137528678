import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Role} from '../models/role.interface';
import {Observable, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RolesService {
	baseUrl: string = `${environment.url}/core/roles`;
	roleSubject: Subject<Role[]> = new Subject<Role[]>();
	constructor(private http: HttpClient) {}

	create(role: Role): Observable<Role> {
		return this.http.post<Role>(this.baseUrl, role);
	}

	findAll(): void {
		this.http.get<Role[]>(this.baseUrl).subscribe((response) => {
			this.roleSubject.next(response);
		});
	}

	findOne(id: number): Observable<Role> {
		return this.http.get<Role>(`${this.baseUrl}/${id}`);
	}

	update(role: Role): Observable<Role> {
		return this.http.put<Role>(`${this.baseUrl}/${role.id}`, role);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}
}
