<section class="p-2">
	<div class="card">
		<div class="flex justify-between">
			<h2>Configurations</h2>
			<button mat-mini-fab color="primary" (click)="createConfiguration()"><mat-icon>add</mat-icon></button>
		</div>
		<mat-form-field class="w-1/2 mb-2" color="primary" subscriptSizing="dynamic">
			<mat-label>Search</mat-label>
			<input
				id="user-searchbar"
				type="text"
				(keyup)="applyFilter($event)"
				placeholder="Search..."
				matInput
				#searchInput
			/>
		</mat-form-field>

    <mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>

    <div class="max-h-[70vh] overflow-auto">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!loading">
        <!--Name Column-->
        <ng-container matColumnDef="configDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Name</th>
          <td mat-cell *matCellDef="let config">
            {{ config.configDescription }}
          </td>
        </ng-container>
        <!--Alias Column-->
        <ng-container matColumnDef="aliasName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Alias</th>
          <td mat-cell *matCellDef="let config">
            {{ config.aliasName ?? config.alias }}
          </td>
        </ng-container>
        <!--Client Column-->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Client</th>
          <td mat-cell *matCellDef="let config">
            {{ config.client }}
          </td>
        </ng-container>
        <!--Interface Column-->
        <ng-container matColumnDef="interface">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Interface</th>
          <td mat-cell *matCellDef="let config">
            {{ config.interface }}
          </td>
        </ng-container>
        <!--Service Column-->
        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Service</th>
          <td mat-cell *matCellDef="let config">
            {{ config.service }}
          </td>
        </ng-container>
        <!--Environment Column-->
        <ng-container matColumnDef="environment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Environment</th>
          <td mat-cell *matCellDef="let config">
            {{ config.environment }}
          </td>
        </ng-container>
        <!--Statusai Column-->
        <ng-container matColumnDef="statusAi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ">Active</th>
          <td mat-cell *matCellDef="let config">
            <mat-slide-toggle
              class="ml-2"
              color="primary"
              (click)="$event.stopPropagation()"
              (toggleChange)="toggleActive(config)"
              [checked]="isActive(config)"
            ></mat-slide-toggle>
          </td>
        </ng-container>
        <!--Actions Column-->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let config">
            <button mat-icon-button (click)="$event.stopPropagation(); updateConfiguration(config)"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button (click)="$event.stopPropagation(); deleteConfiguration(config)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let config; let i = dataIndex; columns: columnsToDisplay" class="cursor-pointer" [routerLink]="config.configId"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row flex" *matNoDataRow>
          <td class="mat-cell p-4" colspan="4" *ngIf="!loading">No data matching the filter "{{ searchInput.value }}"</td>
        </tr>
      </table>
    </div>
	</div>
</section>
