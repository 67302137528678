import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ServiceService} from "../../../core/services/service.service";
import {Service} from "../../../core/models/service.interface";

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent {
  loading: boolean = true;
  service: Service = {} as Service;
  serviceObj: Record<any,any>;
  constructor(public serviceService: ServiceService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.serviceService.findOne( params['serviceId']).subscribe(response => {
        this.service = {...response};
        this.serviceObj = {...response};
        delete this.serviceObj['interfaces'];
        this.loading = false;
      })
    });
  }
}
