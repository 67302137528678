<nav class="px-4 pt-2 font-semibold" *ngIf="breadcrumbs.length > 1">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <ng-container *ngIf="last; else notLast">
      {{ breadcrumb.label | titlecase}}
    </ng-container>
    <ng-template #notLast>
      <a class="underline" [routerLink]="breadcrumb.url">{{ breadcrumb.label | titlecase}}</a> &gt;
    </ng-template>
  </ng-container>
</nav>
