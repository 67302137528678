<div class="p-2">
  <mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>
  <mat-grid-list *ngIf="!loading" cols="3" rowHeight="40vh" gutterSize=".5rem">
    <mat-grid-tile colspan="3" rowspan="1">
      <div class="card">
        <h2>Service</h2>
        <!--Service info-->
        <div class="flex flex-wrap">
          <div *ngFor="let item of serviceObj | keyvalue;" class="w-1/3">
            <p><strong>{{ item.key }}:</strong> {{ item.value }}</p>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="3" rowspan="1">
      <app-interface-list  class="" [interfaces]="service.interfaces" [parentOrgId]="service.orgId" [parentServiceId]="service.id"></app-interface-list>
    </mat-grid-tile>
  </mat-grid-list>
</div>
