import { Component } from '@angular/core';
import {Location} from "../../../core/models/location.interface";
import {LocationService} from "../../../core/services/location.service";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-location',
	templateUrl: './location.component.html',
	styleUrls: ['./location.component.scss']
})
export class LocationComponent {
	loading: boolean = true;
	location: Location = {} as Location;
	loc: Record<any, any>;
	constructor(public locationService: LocationService, private route: ActivatedRoute) {}

	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.locationService.findOne(params['locId']).subscribe((response) => {
				this.loc = {...response};
				delete this.loc['services'];
				delete this.loc['clients'];
				delete this.loc['environments'];
				this.location = {...response};
        this.loading = false;
			});
		});
	}
}
