<div class="p-2">
  <mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>
  <mat-grid-list *ngIf="!loading" cols="3" rowHeight="40vh" gutterSize=".5rem">
    <mat-grid-tile colspan="3" rowspan="1">
      <div class="card">
        <h2>Location</h2>
        <!--Location info-->
        <div class="flex flex-wrap">
          <div *ngFor="let item of loc | keyvalue;" class="w-1/3">
            <p><strong>{{ item.key }}:</strong> {{ item.value }}</p>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <app-client-list  class="" [clients]="location.clients" [parentOrgId]="location.orgId" [parentLocId]="location.id"></app-client-list>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <app-environment-list  class="" [environments]="location.environments" [parentOrgId]="location.orgId" [parentLocId]="location.id"></app-environment-list>
    </mat-grid-tile><mat-grid-tile colspan="1" rowspan="1">
      <app-service-list  class="" [services]="location.services" [parentOrgId]="location.orgId" [parentLocId]="location.id"></app-service-list>
    </mat-grid-tile>
  </mat-grid-list>
</div>

