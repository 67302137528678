import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Organization} from '../models/organization.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  baseUrl: string = `${environment.url}/organization`;
  constructor(private http: HttpClient) {}

  create(organization: Organization): Observable<Organization> {
    return this.http.post<Organization>(this.baseUrl, organization);
  }

  findAll(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.baseUrl);
  }

  findOne(id: number): Observable<Organization> {
    return this.http.get<Organization>(`${this.baseUrl}/${id}`);
  }

  update(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.baseUrl}/${organization.id}`, organization);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
