<div class="max-w-xl m-auto login">
	<h1 class="pt-8 pl-8">Sign In</h1>
	<div class="flex flex-col items-center p-8 h-[300px]" [formGroup]="loginForm">
		<div class="flex-col w-full h-full">
			<mat-form-field appearance="outline" class="w-full">
				<mat-label>Username</mat-label>
				<input name="username" formControlName="username" matInput />
			</mat-form-field>
			<mat-form-field appearance="outline" class="w-full">
				<mat-label>Password</mat-label>
				<input name="password" formControlName="password" matInput [type]="hide ? 'password' : 'text'" />
				<mat-icon class="cursor-pointer" matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
			</mat-form-field>
			<span class="underline underline-offset-2 font-medium cursor-pointer" (click)="forgotPasswordRequest()">Forgot Password?</span>
		</div>
		<div class="w-1/2">
			<button class="w-full" mat-flat-button color="primary" (click)="login()">Login</button>
		</div>
	</div>
</div>
