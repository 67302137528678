import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ServiceCreateComponent} from "../service-create/service-create.component";
import {Service} from "../../../core/models/service.interface";
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ServiceService} from '../../../core/services/service.service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnChanges {
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  sort: MatSort;
  dataSource: MatTableDataSource<Service> = new MatTableDataSource<Service>();
  columnsToDisplay: string[] = ['name', 'description', 'actions'];
  loading: boolean = false;
  @Input() parentOrgId: number;
  @Input() parentLocId: number;
  @Input() services: Service[] = [];

  constructor(public dialog: MatDialog, private snackbar: MatSnackBar, private serviceService: ServiceService) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['services']){
      if(this.services) this.dataSource = new MatTableDataSource<Service>(this.services);
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(ServiceCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.parentOrgId = this.parentOrgId;
    dialogRef.componentInstance.parentLocId = this.parentLocId;
    dialogRef.afterClosed().subscribe((createdService: Service) => {
      if (createdService){
        //update services array with new value
        this.services?.push(createdService);
        this.dataSource = new MatTableDataSource<Service>(this.services);
      }
    })
  }

  openUpdateModal(event: Event, service: Service){
    event.stopPropagation();
    const dialogRef = this.dialog.open(ServiceCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.createMode = false;
    dialogRef.componentInstance.service = service;
    dialogRef.afterClosed().subscribe((updatedService: Service) => {
      if (updatedService) {
        //update service array with new value
        const index = this.services.findIndex((loc: Service) => loc.id === updatedService.id);
        this.services[index] = updatedService;
        this.dataSource = new MatTableDataSource<Service>(this.services);
      }
    })
  }

  deleteService(serv: Service): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Configuration',
        message: `Are you sure you want to delete ${serv.serviceName}?`
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.serviceService.remove(serv.id).subscribe(() => {
            const index: number = this.services.findIndex((service : Service) => service.id === serv.id);
            if (index > -1) {
              this.services.splice(index, 1);
              this.dataSource = new MatTableDataSource<Service>(this.services);
              this.snackbar.open(`Successfully deleted ${serv.serviceName}.`, SnackbarActionEnum.SUCCESS);
            }
          }, error => {
            this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
          })
        }
      });
  }
}
