import {NavigationExtras} from '@angular/router';

export interface navItem {
	routerLink: string;
	icon: string;
	text: string;
	extras?: NavigationExtras;
}
export const navData: navItem[] = [
	{
		routerLink: '/organizations',
		icon: 'business',
		text: 'Organizations'
	},
  {
    routerLink: '/configuration',
    icon: 'settings_applications',
    text: 'Configuration'
  },
  {
    routerLink: '/monitor-log',
    icon: 'visibility',
    text: 'Monitor Log'
  }
];
