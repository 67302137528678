import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
	name: 'commaRemoval'
})
export class CommaRemovalPipe implements PipeTransform {
	constructor(private decimalPipe: DecimalPipe) {}

	transform(input: string, onlyRemoveCommas?: boolean): string {
		let value;
		if (onlyRemoveCommas) {
			return input !== null && input.includes(',') ? input.replaceAll(',', '') : input;
		} else {
			input !== null && input.includes(',') ? (value = input.replaceAll(',', '')) : (value = input);
			const final = this.decimalPipe.transform(parseFloat(value), '.2');
			return final ? final : input;
		}
	}
}
