<h2 mat-dialog-title>{{createMode ? 'Create' : 'Update'}} Service</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
  <div>
    <b>Service Info</b>
    <div class="flex gap-2 flex-wrap pt-2">
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Service</mat-label>
        <input matInput formControlName="service" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Name</mat-label>
        <input matInput formControlName="serviceName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Description</mat-label>
        <input matInput formControlName="serviceDescription" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Term</mat-label>
        <input matInput formControlName="serviceTerm" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>SOW</mat-label>
        <input matInput formControlName="sow" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Project</mat-label>
        <input matInput formControlName="project" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Monthly Fee</mat-label>
        <input matInput formControlName="monthlyFee" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Signed By Name</mat-label>
        <input matInput formControlName="signedByName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Signed By Title</mat-label>
        <input matInput formControlName="signedByTitle" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Signed Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="signedDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<div class="flex justify-end p-4">
  <button mat-button [mat-dialog-close]="false" class="mr-2">Cancel</button>
  <button [disabled]="form.invalid || (!createMode && form.pristine)" mat-raised-button color="primary" (click)="createOrUpdateService()">{{createMode ? 'Create' : 'Update'}}</button>
</div>
