import { Component } from '@angular/core';
import {EndpointService} from "../../../../../core/services/endpoint.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-endpoint',
  templateUrl: './endpoint.component.html',
  styleUrls: ['./endpoint.component.scss']
})
export class EndpointComponent {
  loading: boolean = true;
  // endpoint: Endpoint;
  endpoint: Record<any,any>;
  constructor(public endpointService: EndpointService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.endpointService.findOne( params['endpointId']).subscribe(response => {
        this.endpoint = {...response};
        this.loading = false;
      })
    });
  }
}
