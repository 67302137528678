import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Environment} from "../../../core/models/environment.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EnvironmentService} from "../../../core/services/environment.service";
import {MatDialogRef} from "@angular/material/dialog";
import {SnackbarActionEnum} from "../../../core/enums/snackbar-action.enum";

@Component({
  selector: 'app-environment-create',
  templateUrl: './environment-create.component.html',
  styleUrls: ['./environment-create.component.scss']
})
export class EnvironmentCreateComponent {
  form: FormGroup;
  createMode: boolean = true;
  environment: Environment;
  parentOrgId: number = 0;
  parentLocId: number = 0;

  constructor(private formBuilder: FormBuilder, private snackbar: MatSnackBar, private environmentService: EnvironmentService, public dialogRef: MatDialogRef<EnvironmentCreateComponent>) {
    this.form = this.formBuilder.group({
      id: [0],
      orgId:[this.parentOrgId],
      locId:[this.parentLocId],
      environment: ['', [Validators.required, Validators.maxLength(255)]],
      environmentName: ['', [Validators.required, Validators.maxLength(255)]],
      environmentDescription: ['', [Validators.maxLength(255)]],
      jbEnvironmentId: [0, [Validators.required, Validators.maxLength(10)]],
      jbAgentGroupId: [0, [Validators.required, Validators.maxLength(10)]]
    });
  }

  ngOnInit() {
    if(this.createMode){
      this.form.controls['orgId'].setValue(this.parentOrgId);
      this.form.controls['locId'].setValue(this.parentLocId);
    } else {
      this.form.patchValue(this.environment)
    }
  }

  createOrUpdateEnvironment() {
    if (this.createMode){
      this.environmentService.create(this.form.getRawValue()).subscribe(
        (response: Environment) => {
          this.dialogRef.close(response);
          this.snackbar.open('Environment Successfully Created!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      );
    } else {
      this.environmentService.update(this.form.getRawValue()).subscribe(
        (response: Environment) => {
          this.dialogRef.close(response);
          this.snackbar.open('Environment Updated Successfully!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      )
    }
  }
}
