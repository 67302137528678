<div class="p-2">
  <fieldset class="border border-gray-500 rounded">
    <legend class="ml-4 px-2">Last 30 Days</legend>
    <mat-spinner *ngIf="loading" class="m-auto mt-6"></mat-spinner>
    <div id="chart" *ngIf="!loading">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [responsive]="chartOptions.responsive"
        [xaxis]="chartOptions.xaxis"
        [legend]="chartOptions.legend"
        [fill]="chartOptions.fill"
      ></apx-chart>
    </div>
  </fieldset>
</div>

