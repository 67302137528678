import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {map, Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {User} from "../models/user.interface";
import {UserTypeEnum} from "../enums/user-type.enum";

@Injectable({
  providedIn: 'root'
})
export class InternalGuard implements CanActivate {
  user: User | null;
  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.user.pipe(map((user: User | null): boolean => {
      if (user && (user.type === UserTypeEnum.ADMIN || user.type === UserTypeEnum.INTERNAL)) {
        return true;
      } else {
        this.router.navigate(['/'])
        return false;
      }
    }))
  }

}
