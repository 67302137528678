import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationListComponent } from './configuration-list/configuration-list.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatInputModule} from "@angular/material/input";
import {RouterLink} from "@angular/router";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ConfigurationComponent } from './configuration/configuration.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {CreateConfigurationComponent} from './modals/create-configuration/create-configuration.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {SharedModule} from '../shared/shared.module';
import {UpdateConfigurationComponent} from './modals/update-configuration/update-configuration.component';



@NgModule({
  declarations: [
    ConfigurationListComponent,
    ConfigurationComponent,
    CreateConfigurationComponent,
    UpdateConfigurationComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    RouterLink,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class ConfigurationModule { }
