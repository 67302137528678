import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Endpoint} from "../../../../../core/models/endpoint.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EndpointService} from "../../../../../core/services/endpoint.service";
import {MatDialogRef} from "@angular/material/dialog";
import {SnackbarActionEnum} from "../../../../../core/enums/snackbar-action.enum";
import {EndpointDirectionEnum} from "../../../../../core/enums/endpoint-direction.enum";
import {EndpointTypeEnum} from "../../../../../core/enums/endpoint-type.enum";

@Component({
  selector: 'app-endpoint-create',
  templateUrl: './endpoint-create.component.html',
  styleUrls: ['./endpoint-create.component.scss']
})
export class EndpointCreateComponent {
  form: FormGroup;
  createMode: boolean = true;
  endpoint: Endpoint;
  parentOrgId: number = 0;
  parentInterfaceId: number = 0;
  parentServiceId: number = 0;
  endpointTypeOptions: [string, string][] = [];
  endpointDirectionOptions: [string, string][] = [];

  constructor(private formBuilder: FormBuilder, private snackbar: MatSnackBar, private endpointService: EndpointService, public dialogRef: MatDialogRef<EndpointCreateComponent>) {
    this.form = this.formBuilder.group({
      id: [0],
      orgId:[this.parentOrgId],
      serviceId:[this.parentServiceId],
      interfaceId:[this.parentInterfaceId],
      endpointName: ['', [Validators.required, Validators.maxLength(255)]],
      endpointDescription: ['', [Validators.required, Validators.maxLength(255)]],
      endpointType: ['', [Validators.required, Validators.maxLength(255)]],
      endpointDirection: ['', [Validators.required, Validators.maxLength(255)]],
      endpointConnectionString: ['', [Validators.maxLength(255)]],
      interface: ['', [Validators.required, Validators.maxLength(255)]],
    });
    this.endpointDirectionOptions = Object.entries(EndpointDirectionEnum);
    this.endpointTypeOptions = Object.entries(EndpointTypeEnum);
  }

  ngOnInit() {
    if(this.createMode){
      this.form.controls['orgId'].setValue(this.parentOrgId);
      this.form.controls['interfaceId'].setValue(this.parentInterfaceId);
      this.form.controls['serviceId'].setValue(this.parentServiceId);
    } else {
      this.form.patchValue(this.endpoint)
    }
  }

  createOrUpdateEndpoint() {
    if (this.createMode){
      this.endpointService.create(this.form.getRawValue()).subscribe(
        (response: Endpoint) => {
          this.dialogRef.close(response);
          this.snackbar.open('Endpoint Successfully Created!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      );
    } else {
      this.endpointService.update(this.form.getRawValue()).subscribe(
        (response: Endpoint) => {
          this.dialogRef.close(response);
          this.snackbar.open('Endpoint Updated Successfully!', SnackbarActionEnum.SUCCESS);
        },
        (error) => {
          this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
        }
      )
    }
  }
}
