import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../enums/snackbar-action.enum';
import {ResetPassword} from '../../models/reset-password.interface';

@Component({
	selector: 'app-first-login-reset-password',
	templateUrl: './first-login-reset-password.component.html',
	styleUrls: ['./first-login-reset-password.component.scss']
})
export class FirstLoginResetPasswordComponent {
	hide = true;
	resetForm = this.fb.group(
		{
			password: new FormControl(''),
			confirmPassword: new FormControl('', [])
		},
		{validator: this.passwordsMatch}
	);
	errorMessages: string[] = [];

	constructor(private authService: AuthService, private router: Router, private snackBar: MatSnackBar, private fb: FormBuilder) {}

	ngOnInit() {
		if (this.authService.userLoggedIn) {
			this.router.navigate(['/']);
		}
	}
	passwordsMatch(form: FormGroup) {
		if (form.value.password !== form.value.confirmPassword) {
			return {passwordsMatch: true};
		}
		return null;
	}
	resetPassword() {
		let payload: ResetPassword = this.resetForm.getRawValue();
		payload.username = this.authService.attemptedUser;

		this.authService.firstLoginResetPassword(payload).subscribe(
			(response) => {
				this.authService.getCurrentUser().subscribe((response) => {
					this.authService.userDataSource.next(response);
				});
				this.authService.userLoggedIn = true;

				if (this.authService.redirectUrl && this.authService.redirectUrl !== '/login') {
					this.router.navigate([this.authService.redirectUrl]);
					this.authService.redirectUrl = null;
				} else {
					//Default app landing page
					this.router.navigate(['/']);
				}
				this.snackBar.open('Password Successfully Updated', SnackbarActionEnum.SUCCESS);
			},
			(error) => {
				if (Array.isArray(error.error.message)) {
					this.errorMessages = error.error.message;
				} else {
					this.errorMessages = [error.error.message];
				}
				this.snackBar.open('Failed to Reset Password', SnackbarActionEnum.ERROR);
			}
		);
	}
}
