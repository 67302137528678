<h2 mat-dialog-title>{{createMode ? 'Create' : 'Update'}} Endpoint</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
  <div>
    <b>Endpoint Info</b>
    <div class="flex gap-2 flex-wrap pt-2">
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Name</mat-label>
        <input matInput formControlName="endpointName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Description</mat-label>
        <input matInput formControlName="endpointDescription" />
      </mat-form-field>


      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Type</mat-label>
        <mat-select formControlName="endpointType">
          <mat-option *ngFor="let option of endpointTypeOptions" [value]="option[0]">
            {{option[1]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Interface</mat-label>
        <input matInput formControlName="interface" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Connection String</mat-label>
        <input matInput formControlName="endpointConnectionString" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Direction</mat-label>
        <mat-select formControlName="endpointDirection">
          <mat-option *ngFor="let option of endpointDirectionOptions" [value]="option[0]">
            {{option[1]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<div class="flex justify-end p-4">
  <button mat-button [mat-dialog-close]="false" class="mr-2">Cancel</button>
  <button [disabled]="form.invalid || (!createMode && form.pristine)" mat-raised-button color="primary" (click)="createOrUpdateEndpoint()">{{createMode ? 'Create' : 'Update'}}</button>
</div>
