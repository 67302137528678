import {Component, OnInit} from '@angular/core';
import {OrganizationService} from "../../core/services/organization.service";
import {Organization} from "../../core/models/organization.interface";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit{
  loading: boolean = true;
  organization: Organization;
  org: Record<any,any>;
  constructor(public organizationService: OrganizationService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.organizationService.findOne( params['orgId']).subscribe(response => {
        this.org ={...response};
        delete this.org['locations'];
        this.organization = {...response};
        this.loading = false;
      })
    });
  }

}
