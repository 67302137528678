<div class="m-auto flex flex-row justify-center w-full pt-8 pl-8">
	<div class="max-w-[700px]">
		<h1 class="">Reset Password</h1>
		<div *ngIf="!requestSent" [formGroup]="requestForm" class="max-w-[450px]">
			<span>
				Please enter the email address associated with your account. We’ll send you an email with instructions on how to reset your
				password.
			</span>
			<mat-form-field class="w-full mt-4">
				<mat-label>Email</mat-label>
				<input name="email" formControlName="username" matInput />
			</mat-form-field>
			<button class="w-full" mat-flat-button color="primary" (click)="resetRequest()">Send Email</button>
		</div>
		<div *ngIf="requestSent" [formGroup]="resetForm" class="w-full">
			<span>
				Please check your email address associated with your account. Enter the confirmation code from the email along with your new
				password.
			</span>
			<div class="flex flex-row">
				<div class="w-3/4">
					<mat-form-field class="w-full mt-4">
						<mat-label>Confirmation Code</mat-label>
						<input formControlName="confirmationCode" matInput />
					</mat-form-field>
					<mat-form-field class="w-full">
						<mat-label>Password</mat-label>
						<input name="password" formControlName="password" matInput [type]="hide ? 'password' : 'text'" />
						<mat-icon class="cursor-pointer" matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
					</mat-form-field>
					<mat-form-field class="w-full" subscriptSizing="dynamic">
						<mat-label>Confirm Password</mat-label>
						<input name="confirmPassword" formControlName="confirmPassword" matInput [type]="hide ? 'password' : 'text'" />
					</mat-form-field>
					<div class="h-12">
						<mat-error
							*ngIf="(resetForm.hasError('passwordsMatch') && resetForm.controls['confirmPassword'].dirty) || errorMessages.length"
						>
							<span *ngIf="resetForm.hasError('passwordsMatch')">Passwords do not match <br /></span>
							<span *ngFor="let message of errorMessages">{{ message.charAt(0).toUpperCase() + message.slice(1) }}<br /></span>
						</mat-error>
					</div>
					<button
						class="w-full"
						mat-flat-button
						color="primary"
						[disabled]="resetForm.hasError('passwordsMatch')"
						(click)="resetPassword()"
					>
						Reset Password
					</button>
				</div>
				<div class="ml-6 mt-3">
					<span class="font-medium">Password Requirements:</span><br />
					<span>8 Characters Minimum Length</span><br />
					<span>At least 1 number</span><br />
					<span>At least 1 special character</span><br />
					<span>At least 1 uppercase letter</span><br />
					<span>At least 1 lowercase letter</span>
				</div>
			</div>
		</div>
	</div>
</div>
