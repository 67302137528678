import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Dashboard} from "../models/dashboard.interface";

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	baseUrl: string = `${environment.url}/dashboard`;

  constructor(private http: HttpClient) {}

	getDashboard(): Observable<Dashboard> {
		return this.http.get<Dashboard>(this.baseUrl);
	}

}
