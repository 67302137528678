import {Component, EventEmitter, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/user.interface';
import {navData, navItem} from './nav-options';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../services/user.service';
import {UserTypeEnum} from "../../enums/user-type.enum";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
@Component({
	selector: 'app-main-toolbar',
	templateUrl: './main-toolbar.component.html',
	styleUrls: ['./main-toolbar.component.scss'],
  animations: [
    trigger('hamburgerX', [
      /*
        state hamburger => is the regular 3 lines style.
        states topX, hide, and bottomX => used to style the X element
      */
      state('hamburger', style({})),
      // style top bar to create the X
      state(
        'topX',
        style({
          transform: 'rotate(45deg)',
          transformOrigin: 'left',
          margin: '6px',
        })
      ),
      // hides element when create the X (used in the middle bar)
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      // style bottom bar to create the X
      state(
        'bottomX',
        style({
          transform: 'rotate(-45deg)',
          transformOrigin: 'left',
          margin: '6px',
        })
      ),
      transition('* => *', [
        animate('0.2s'), // controls animation speed
      ]),
    ]),
  ],
})
export class MainToolbarComponent {
	user: User | null;
	navOptions: navItem[];
  isHamburger = true;
  @Output('sideNavToggle') sideNavToggleEmitter: EventEmitter<boolean>;
	constructor(private authService: AuthService, private router: Router, private dialog: MatDialog, private userService: UserService) {
    this.sideNavToggleEmitter = new EventEmitter(false);
  }

	ngOnInit(): void {
		this.authService.user.subscribe((user: User | null): void => {
			this.user = user;
		});
//		this.navOptions = navData;
    this.navOptions = navData.filter(navOption => this.user?.type && navOption.showFor.includes(this.user?.type));
	}

  onHamburgerClick() {
    this.isHamburger = !this.isHamburger;
    this.sideNavToggleEmitter.emit(this.isHamburger)
  }
  isAdmin(): boolean {
    return !!(this.user?.type && this.user.type === UserTypeEnum.ADMIN);
  }

  isInternal(): boolean {
    return !!(this.user?.type && (this.user.type === UserTypeEnum.ADMIN || this.user.type === UserTypeEnum.INTERNAL));
  }

	getInitials(): string {
		const name: string | undefined = this.user?.firstName?.concat(' ', this.user?.lastName);
		if (!name) {
			return '';
		}
		return name
			.split(' ')
			.map((n: string) => n[0])
			.join('');
	}

	getColor(): string {
		const name: string | undefined = this.user?.firstName?.concat(' ', this.user?.lastName);
		return this.userService.getUserColor(name);
	}

	navigate(item: navItem): void {
		this.router.navigate([item.routerLink], item.extras);
	}

	logout() {
		this.authService.logout();
	}
}
