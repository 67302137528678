import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Endpoint} from '../models/endpoint.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {
  baseUrl: string = `${environment.url}/endpoint`;
  constructor(private http: HttpClient) {}

  create(endpoint: Endpoint): Observable<Endpoint> {
    return this.http.post<Endpoint>(this.baseUrl, endpoint);
  }

  findAll(): Observable<Endpoint[]> {
    return this.http.get<Endpoint[]>(this.baseUrl);
  }

  findOne(id: number): Observable<Endpoint> {
    return this.http.get<Endpoint>(`${this.baseUrl}/${id}`);
  }

  update(endpoint: Endpoint): Observable<Endpoint> {
    return this.http.put<Endpoint>(`${this.baseUrl}/${endpoint.id}`, endpoint);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
