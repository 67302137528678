<div *ngIf="aliasConfig" class="flex flex-col p-2 w-full">
  <label class="text-xl font-medium">{{aliasConfig.configDescription}}</label>
  <div class="flex flex-row">
    <div class="flex flex-col w-1/3">
      <div class="flex flex-col space-y-2">
        <label class="font-medium text-lg">General Info</label>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Client:</label>
          <label class="underline cursor-pointer" (click)="routeTo('client')">{{aliasConfig.client}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Service:</label>
          <label class="underline cursor-pointer" (click)="routeTo('service')">{{aliasConfig.service}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Environment:</label>
          <label class="underline cursor-pointer" (click)="routeTo('environment')">{{aliasConfig.environment}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Interface:</label>
          <label class="underline cursor-pointer" (click)="routeTo('interface')">{{aliasConfig.interface}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Interface Type:</label><label>{{aliasConfig.interfaceType}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Status:</label><label>{{aliasConfig.statusAi === 'A' ? 'Active' : 'Inactive'}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Log Level:</label><label>{{aliasConfig.logLevel}}</label>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-1/3">
      <div class="flex flex-col space-y-2">
        <label class="font-medium text-lg">Alias/Endpoint Info</label>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Alias:</label><label>{{aliasConfig.alias}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Alias Name:</label><label>{{aliasConfig.aliasName}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Alias Description:</label><label>{{aliasConfig.aliasDescription}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Endpoint Name List:</label><label>{{aliasConfig.endpointNameList}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Endpoint Type List:</label><label>{{aliasConfig.endpointTypeList}}</label>
        </div>
        <div class="flex flex-row">
          <label class="font-medium pr-2">Endpoint Dir List:</label><label>{{aliasConfig.endpointDirList}}</label>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-1/3">
      <div class="flex flex-col space-y-2">
        <label class="font-medium text-lg">Notification Info</label>
        <div *ngIf="aliasConfig.notifyApiUrl" class="flex flex-row">
          <label class="font-medium pr-2">API URL:</label><label>{{aliasConfig.notifyApiUrl}}</label>
        </div>
        <div *ngIf="aliasConfig.notifyEmailToList" class="flex flex-row">
          <label class="font-medium pr-2">Email To List:</label><label>{{aliasConfig.notifyEmailToList}}</label>
        </div>
        <div *ngIf="aliasConfig.notifyEmailCcList" class="flex flex-row">
          <label class="font-medium pr-2">Email CC List:</label><label>{{aliasConfig.notifyEmailCcList}}</label>
        </div>
        <div *ngIf="aliasConfig.notifyEmailBccList" class="flex flex-row">
          <label class="font-medium pr-2">Email BCC List:</label><label>{{aliasConfig.notifyEmailBccList}}</label>
        </div>
        <div *ngIf="aliasConfig.notifyTextList" class="flex flex-row">
          <label class="font-medium pr-2">Text List:</label><label>{{aliasConfig.notifyTextList}}</label>
        </div>
        <div *ngIf="aliasConfig.notifyMsgSubject" class="flex flex-row">
          <label class="font-medium pr-2">Message Subject:</label><label>{{aliasConfig.notifyMsgSubject}}</label>
        </div>
        <div *ngIf="aliasConfig.notifyMsgBody" class="flex flex-row">
          <label class="font-medium pr-2">Message Body:</label><label>{{aliasConfig.notifyMsgBody}}</label>
        </div>
      </div>
    </div>
  </div>
</div>


