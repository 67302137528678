import {Component, OnChanges, Input, SimpleChanges, ViewChild} from '@angular/core';
import {Location} from "../../../core/models/location.interface"
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {LocationCreateComponent} from "../location-create/location-create.component";
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LocationService} from '../../../core/services/location.service';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnChanges{
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  sort: MatSort;
  dataSource: MatTableDataSource<Location> = new MatTableDataSource<Location>();
  columnsToDisplay: string[] = ['name', 'contact', 'actions'];
  loading: boolean = false;
  @Input() parentOrgId: number;
  @Input() locations: Location[] = [];
  constructor(public dialog: MatDialog, private snackbar: MatSnackBar, private locationService: LocationService) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['locations']){
      if(this.locations) this.dataSource = new MatTableDataSource<Location>(this.locations);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(LocationCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.parentOrgId = this.parentOrgId;
    dialogRef.afterClosed().subscribe((createdLoc: Location) => {
      if (createdLoc){
        //update locations array with new value
        this.locations?.push(createdLoc);
        this.dataSource = new MatTableDataSource<Location>(this.locations);
      }
    })
  }

  openUpdateModal(event: Event, location: Location){
    event.stopPropagation();
    const dialogRef = this.dialog.open(LocationCreateComponent, {
      width: '70vw',
      maxWidth: '800px'
    })
    dialogRef.componentInstance.createMode = false;
    dialogRef.componentInstance.location = location;
    dialogRef.afterClosed().subscribe((updatedLoc: Location) => {
      if (updatedLoc) {
        //update location array with new value
        const index = this.locations.findIndex((loc: Location) => loc.id === updatedLoc.id);
        this.locations[index] = updatedLoc;
        this.dataSource = new MatTableDataSource<Location>(this.locations);
      }
    })
  }

  deleteLocation(loc: Location): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Configuration',
        message: `Are you sure you want to delete ${loc.locName}?`
      }
    }).afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.locationService.remove(loc.id).subscribe(() => {
            const index: number = this.locations.findIndex((location : Location) => location.id === loc.id);
            if (index > -1) {
              this.locations.splice(index, 1);
              this.dataSource = new MatTableDataSource<Location>(this.locations);
              this.snackbar.open(`Successfully deleted ${loc.locName}.`, SnackbarActionEnum.SUCCESS);
            }
          }, error => {
            this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
          })
        }
      });
  }
}
