<h2 mat-dialog-title>{{createMode ? 'Create' : 'Update'}} Location</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
  <div>
    <b>Location Info</b>
    <div class="flex gap-2 flex-wrap pt-2">
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Name</mat-label>
        <input matInput formControlName="locName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Address</mat-label>
        <input matInput formControlName="locAddr1" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Address 2</mat-label>
        <input matInput formControlName="locAddr2" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>City</mat-label>
        <input matInput formControlName="locCity" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>State</mat-label>
        <input matInput formControlName="locState" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Postal</mat-label>
        <input matInput formControlName="locPostal" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Country</mat-label>
        <input matInput formControlName="locCtry" />
      </mat-form-field>
    </div>
  </div>
  <div class="mt-2">
    <b>Rep Info</b>
    <div class="flex gap-2 flex-wrap pt-2">
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Rep Name</mat-label>
        <input matInput formControlName="repName" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Rep Email</mat-label>
        <input matInput formControlName="repEmail" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Rep Phone</mat-label>
        <input matInput formControlName="repPhone" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Rep Notes</mat-label>
        <input matInput formControlName="repNotes" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="">
        <mat-label>Rep Fax</mat-label>
        <input matInput formControlName="repFax" />
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<div class="flex justify-end p-4">
  <button mat-button [mat-dialog-close]="false" class="mr-2">Cancel</button>
  <button [disabled]="form.invalid || (!createMode && form.pristine)" mat-raised-button color="primary" (click)="createOrUpdateLocation()">{{createMode ? 'Create' : 'Update'}}</button>
</div>
