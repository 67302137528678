import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Client} from '../models/client.interface';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  baseUrl: string = `${environment.url}/client`;
  constructor(private http: HttpClient) {}

  create(client: Client): Observable<Client> {
    return this.http.post<Client>(this.baseUrl, client);
  }

  findAll(locId?: number): Observable<Client[]> {
    let params: HttpParams = new HttpParams();

    if (locId) {
      params = params.append('locId', locId);
    }

    return this.http.get<Client[]>(this.baseUrl, {params});
  }

  findOne(id: number): Observable<Client> {
    return this.http.get<Client>(`${this.baseUrl}/${id}`);
  }

  update(client: Client): Observable<Client> {
    return this.http.put<Client>(`${this.baseUrl}/${client.id}`, client);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
