import { Component } from '@angular/core';
import {InterfaceService} from "../../../../../core/services/interface.service";
import {ActivatedRoute} from "@angular/router";
import {Interface} from "../../../../../core/models/interface.interface";

@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss']
})
export class InterfaceComponent {
  loading: boolean = true;
  interfaceObj: Interface = {} as Interface;
  interface: Record<any,any>;
  constructor(public interfaceService: InterfaceService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.interfaceService.findOne( params['interfaceId']).subscribe(response => {
        this.interface = {...response};
        this.interfaceObj = {...response};
        delete this.interface['endpoints'];
        this.loading = false;
      })
    });
  }
}
