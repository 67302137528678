import { Component } from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {SnackbarActionEnum} from "../../enums/snackbar-action.enum";
import {AuthService} from "../../services/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ChangePassword} from "../../models/change-password.interface";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  hidePassword = true;

  accountFormGroup = new FormGroup({
    changePasswordFormGroup: new FormGroup({
      password: new FormControl(),
      newPassword: new FormControl(),
      confirmPassword: new FormControl(),
    },
      (group: AbstractControl) => this.changePasswordValidator(group)
    )
  });
  errorMessages: string[] = [];

  constructor(private authService: AuthService, private snackBar: MatSnackBar) {
  }

  changePasswordValidator(group: AbstractControl) {
    const form = group as FormGroup;
    let error = null as any;
    if(form.value.newPassword > '') {
      if (form.value.password === '') {
        error = {passwordRequired: true};
      }
      if (form.value.newPassword !== form.value.confirmPassword) {
        if (error) {
          error.passwordsMatch = true;
        } else {
          error = {passwordsMatch: true};
        }
      }
      if (form.value.newPassword.length < 8) {
        if (error) {
          error.minLength = true;
        } else {
          error = {minLength: true};
        }
      }
      if (!/\d/.test(form.value.newPassword)) {
        if (error) {
          error.oneNumber = true;
        } else {
          error = {oneNumber: true};
        }
      }
      if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(form.value.newPassword)) {
        if (error) {
          error.oneSpecial = true;
        } else {
          error = {oneSpecial: true};
        }
      }
      if (!/[A-Z]/.test(form.value.newPassword)) {
        if (error) {
          error.oneUpper = true;
        } else {
          error = {oneUpper: true};
        }
      }
      if (!/[a-z]/.test(form.value.newPassword)) {
        if (error) {
          error.oneLower = true;
        } else {
          error = {oneLower: true};
        }
      }
    } else {
      error = {noChange: true}
    }
    return error;
  }

  get changePasswordGroup(): FormGroup {
    return this.accountFormGroup.controls.changePasswordFormGroup;
  }

  resetPassword() {
    let payload: ChangePassword = this.changePasswordGroup.getRawValue();
    this.errorMessages = [];

    this.authService.changeUserPassword(payload).subscribe({
        next: (response) => {
          this.authService.getCurrentUser().subscribe((response) => {
            this.authService.userDataSource.next(response);
          });
          this.authService.userLoggedIn = true;

          this.changePasswordGroup.reset();
          this.snackBar.open('Password Successfully Changed', SnackbarActionEnum.SUCCESS);
        },
        error:  (error) => {
            if (Array.isArray(error.error.message)) {
              this.errorMessages = error.error.message;
            } else {
              this.errorMessages = [error.error.message];
            }
            this.snackBar.open('Failed to Change Password', SnackbarActionEnum.ERROR);
          }
      }
    );
  }

}
